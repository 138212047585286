import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { OrderMarker } from '@/Enums';
import { salesPageAsyncActions } from '@/Pages/Sales/services';

export const useAddressActions = (onClose: () => void, orderId?: number) => {
  const dispatch = useDispatch();

  const changeAddressDisabledState = useCallback(
    (addressEditDisabled?: boolean) => {
      if (!orderId) return;
      if (addressEditDisabled) {
        MpOrdersMarkingApi.changeAddressEditDisabled({
          orderIds: [orderId],
          orderMarker: OrderMarker.AddressEditEnabled,
        }).then(() => {
          dispatch(salesPageAsyncActions.getSalesFromElastic());
          onClose();
        });
      } else {
        MpOrdersMarkingApi.changeAddressEditDisabled({
          orderIds: [orderId],
          orderMarker: OrderMarker.AddressEditDisabled,
        }).then(() => {
          dispatch(salesPageAsyncActions.getSalesFromElastic());
          onClose();
        });
      } // if
    },
    [dispatch, orderId, onClose],
  );

  return {
    changeAddressDisabledState,
  };
};
