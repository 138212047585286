import { useLocalization } from '@/Hooks';
import { ColumnInfo, ColumnType, OrderTableRow } from '@/Types';
import { useMemo } from 'react';

export function useSalesColumnInfo() {
  const { orderColumns } = useLocalization();

  const columnInfo: ColumnInfo<OrderTableRow>[] = useMemo(
    () => [
      //#region Order columns
      { columnType: ColumnType.String, fieldName: 'userOrderId', headerText: orderColumns.id },
      { columnType: ColumnType.String, fieldName: 'mpOrderNumber', headerText: orderColumns.mpOrderNumber },
      { columnType: ColumnType.String, fieldName: 'extendedOrderId', headerText: orderColumns.extendedOrderId },
      { columnType: ColumnType.String, fieldName: 'orderType', headerText: orderColumns.orderType },
      { columnType: ColumnType.String, fieldName: 'orderStatus', headerText: orderColumns.orderStatus },
      { columnType: ColumnType.String, fieldName: 'cancelStatus', headerText: orderColumns.cancelStatus },
      {
        columnType: ColumnType.String,
        fieldName: 'buyerMarketplaceAccount',
        headerText: orderColumns.buyerMarketplaceAccount,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'sellerMarketplaceAccount',
        headerText: orderColumns.sellerMarketplaceAccount,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'accountName',
        headerText: orderColumns.accountName,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'mandatName',
        headerText: orderColumns.mandatName,
        defaultVisible: false,
      },
      { columnType: ColumnType.String, fieldName: 'buyerEmail', headerText: orderColumns.buyerEmail },
      { columnType: ColumnType.String, fieldName: 'customerNumber', headerText: orderColumns.customerNumber },

      { columnType: ColumnType.Currency, fieldName: 'refundAmount', headerText: orderColumns.refundAmount },
      { columnType: ColumnType.String, fieldName: 'refundStatus', headerText: orderColumns.refundStatus },
      { columnType: ColumnType.String, fieldName: 'refundType', headerText: orderColumns.refundType },
      { columnType: ColumnType.DateTime, fieldName: 'refundTime', headerText: orderColumns.refundTime },

      // Prices
      {
        columnType: ColumnType.Currency,
        fieldName: 'priceBruttoWithShipping',
        headerText: orderColumns.priceBruttoWithShipping,
      },
      { columnType: ColumnType.Currency, fieldName: 'amountPaid', headerText: orderColumns.amountPaid },
      { columnType: ColumnType.Currency, fieldName: 'shippingPrice', headerText: orderColumns.shippingPrice },
      {
        columnType: ColumnType.Currency,
        fieldName: 'actualShippingCostNetto',
        headerText: orderColumns.actualShippingCostNetto,
      },
      { columnType: ColumnType.Currency, fieldName: 'openAmount', headerText: orderColumns.openAmount },
      { columnType: ColumnType.String, fieldName: 'currencyId', headerText: orderColumns.currencyId },
      { columnType: ColumnType.String, fieldName: 'paymentStatus', headerText: orderColumns.paymentStatus },
      { columnType: ColumnType.String, fieldName: 'paymentMethod', headerText: orderColumns.paymentMethod },
      { columnType: ColumnType.Currency, fieldName: 'orderFixFee', headerText: orderColumns.orderFixFee },
      { columnType: ColumnType.Currency, fieldName: 'profit', headerText: orderColumns.profit },

      // Dates
      { columnType: ColumnType.DateTime, fieldName: 'appCreatedTime', headerText: orderColumns.appCreatedTime },
      {
        columnType: ColumnType.DateTime,
        fieldName: 'appLastModifiedTime',
        headerText: orderColumns.appLastModifiedTime,
      },
      { columnType: ColumnType.DateTime, fieldName: 'synchTime', headerText: orderColumns.synchTime },
      { columnType: ColumnType.DateTime, fieldName: 'mpLastModifiedTime', headerText: orderColumns.mpLastModifiedTime },
      { columnType: ColumnType.DateTime, fieldName: 'mpCreatedTime', headerText: orderColumns.mpCreatedTime },
      { columnType: ColumnType.DateTime, fieldName: 'paidTime', headerText: orderColumns.paidTime },
      { columnType: ColumnType.DateTime, fieldName: 'actualDeliveryTime', headerText: orderColumns.actualDeliveryTime },

      // Shipping
      {
        columnType: ColumnType.String,
        fieldName: 'shippingMethodDescription',
        headerText: orderColumns.shippingMethod,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'actualShippingMethodDescription',
        headerText: orderColumns.actualShippingMethod1,
      },
      {
        columnType: ColumnType.DateTime,
        fieldName: 'estimatedDeliveryTimeMax',
        headerText: orderColumns.estimatedDeliveryTimeMax,
      },
      { columnType: ColumnType.DateTime, fieldName: 'handleByTime', headerText: orderColumns.handleByTime },

      { columnType: ColumnType.String, fieldName: 'shippingCompanyName', headerText: orderColumns.shippingCompanyName },
      { columnType: ColumnType.String, fieldName: 'shippingFirstName', headerText: orderColumns.shippingFirstName },
      { columnType: ColumnType.String, fieldName: 'shippingLastName', headerText: orderColumns.shippingLastName },
      {
        columnType: ColumnType.String,
        fieldName: 'shippingAdditionalLine',
        headerText: orderColumns.shippingAdditionalLine,
      },
      { columnType: ColumnType.String, fieldName: 'shippingStreet', headerText: orderColumns.shippingStreet },
      { columnType: ColumnType.String, fieldName: 'shippingHouseNo', headerText: orderColumns.shippingHouseNo },
      { columnType: ColumnType.String, fieldName: 'shippingPostalCode', headerText: orderColumns.shippingPostalCode },
      { columnType: ColumnType.String, fieldName: 'shippingCityName', headerText: orderColumns.shippingCityName },
      { columnType: ColumnType.String, fieldName: 'shippingCountryName', headerText: orderColumns.shippingCountryName },
      { columnType: ColumnType.String, fieldName: 'shippingPhoneNumber', headerText: orderColumns.shippingPhoneNumber },

      { columnType: ColumnType.String, fieldName: 'invoiceCompanyName', headerText: orderColumns.invoiceCompanyName },
      { columnType: ColumnType.String, fieldName: 'invoiceFirstName', headerText: orderColumns.invoiceFirstName },
      { columnType: ColumnType.String, fieldName: 'invoiceLastName', headerText: orderColumns.invoiceLastName },
      {
        columnType: ColumnType.String,
        fieldName: 'invoiceAdditionalLine',
        headerText: orderColumns.invoiceAdditionalLine,
      },
      { columnType: ColumnType.String, fieldName: 'invoiceStreet', headerText: orderColumns.invoiceStreet },
      { columnType: ColumnType.String, fieldName: 'invoiceHouseNo', headerText: orderColumns.invoiceHouseNo },
      { columnType: ColumnType.String, fieldName: 'invoicePostalCode', headerText: orderColumns.invoicePostalCode },
      { columnType: ColumnType.String, fieldName: 'invoiceCityName', headerText: orderColumns.invoiceCityName },
      { columnType: ColumnType.String, fieldName: 'invoiceCountryName', headerText: orderColumns.invoiceCountryName },
      { columnType: ColumnType.String, fieldName: 'invoicePhoneNumber', headerText: orderColumns.invoicePhoneNumber },

      { columnType: ColumnType.String, fieldName: 'internalNote', headerText: orderColumns.internalNote },
      { columnType: ColumnType.String, fieldName: 'externalNote', headerText: orderColumns.externalNote },

      // Numbers
      { columnType: ColumnType.String, fieldName: 'deliveryNoteNumber', headerText: orderColumns.deliveryNoteNumber },
      { columnType: ColumnType.String, fieldName: 'orderNumber', headerText: orderColumns.orderNumber },

      // { columnType: ColumnType.String, fieldName: 'invoiceNumber', headerText: orderColumns.invoiceNumber },
      // {
      //   columnType: ColumnType.DateTime,
      //   fieldName: 'invoiceNumberCreationTime',
      //   headerText: orderColumns.invoiceNumberCreatedTime,
      // },
      // { columnType: ColumnType.DateTime, fieldName: 'invoicePrinted', headerText: orderColumns.invoicePrinted },
      // { columnType: ColumnType.DateTime, fieldName: 'invoiceMailed', headerText: orderColumns.invoiceMailed },
      //
      // { columnType: ColumnType.String, fieldName: 'cancelInvoiceNumber', headerText: orderColumns.cancelInvoiceNumber },
      // {
      //   columnType: ColumnType.DateTime,
      //   fieldName: 'cancelInvoiceNumberCreationTime',
      //   headerText: orderColumns.cancelInvoiceNumberCreatedTime,
      // },
      // {
      //   columnType: ColumnType.DateTime,
      //   fieldName: 'cancelInvoicePrinted',
      //   headerText: orderColumns.cancelInvoicePrinted,
      // },
      // { columnType: ColumnType.DateTime, fieldName: 'cancelInvoiceMailed', headerText: orderColumns.cancelInvoiceMailed },

      { columnType: ColumnType.String, fieldName: 'buyerNote', headerText: orderColumns.buyerNote },

      { columnType: ColumnType.DateTime, fieldName: 'archived', headerText: orderColumns.archived },
      { columnType: ColumnType.DateTime, fieldName: 'exported', headerText: orderColumns.exported },

      { columnType: ColumnType.DateTime, fieldName: 'canceledNotPaid', headerText: orderColumns.canceledNotPaid },
      { columnType: ColumnType.DateTime, fieldName: 'canceledRefunded', headerText: orderColumns.canceledRefunded },

      //#region Orderitem columns
      { columnType: ColumnType.String, fieldName: 'orderItemId', headerText: orderColumns.orderItemId },
      { columnType: ColumnType.String, fieldName: 'marketplaceId', headerText: orderColumns.marketplaceId },
      { columnType: ColumnType.String, fieldName: 'transactionId', headerText: orderColumns.transactionId },
      { columnType: ColumnType.Boolean, fieldName: 'ebayPlus', headerText: orderColumns.ebayPlus },
      { columnType: ColumnType.String, fieldName: 'sku', headerText: orderColumns.sku },
      { columnType: ColumnType.String, fieldName: 'title', headerText: orderColumns.title },
      { columnType: ColumnType.String, fieldName: 'saleFormat', headerText: orderColumns.saleFormat },

      // Prices
      {
        columnType: ColumnType.Currency,
        fieldName: 'priceNetto',
        headerText: orderColumns.priceNetto,
      },
      {
        columnType: ColumnType.Currency,
        fieldName: 'priceBrutto',
        headerText: orderColumns.priceBrutto,
      },
      {
        columnType: ColumnType.Currency,
        fieldName: 'totalPriceNettoWithShipping',
        headerText: orderColumns.totalPriceNettoWithShipping,
      },
      {
        columnType: ColumnType.Currency,
        fieldName: 'totalPriceBruttoWithShipping',
        headerText: orderColumns.totalPriceBruttoWithShipping,
      },
      {
        columnType: ColumnType.Currency,
        fieldName: 'totalPriceNettoWithoutShipping',
        headerText: orderColumns.totalPriceNettoWithoutShipping,
      },
      {
        columnType: ColumnType.Currency,
        fieldName: 'totalPriceBruttoWithoutShipping',
        headerText: orderColumns.totalPriceBruttoWithoutShipping,
      },
      { columnType: ColumnType.Currency, fieldName: 'salesTaxPercent', headerText: orderColumns.salesTaxPercent },
      {
        columnType: ColumnType.Currency,
        fieldName: 'salesTaxTotalWithShipping',
        headerText: orderColumns.salesTaxTotalWithShipping,
      },
      {
        columnType: ColumnType.Currency,
        fieldName: 'shippingCostBruttoPerPosition',
        headerText: orderColumns.shippingCostBruttoPerPosition,
      },
      {
        columnType: ColumnType.Currency,
        fieldName: 'transactionVariableFee',
        headerText: orderColumns.transactionVariableFee,
      },
      { columnType: ColumnType.Currency, fieldName: 'promotionFee', headerText: orderColumns.promotionFee },
      { columnType: ColumnType.String, fieldName: 'quantity', headerText: orderColumns.quantity },

      // Shipping
      { columnType: ColumnType.DateTime, fieldName: 'shippedTime', headerText: orderColumns.shippedTime },
      { columnType: ColumnType.String, fieldName: 'trackingNumber', headerText: orderColumns.trackingNumber },

      // Feedbacks
      { columnType: ColumnType.DateTime, fieldName: 'buyerFeedbackTime', headerText: orderColumns.buyerFeedbackTime },
      { columnType: ColumnType.String, fieldName: 'buyerFeedbackType', headerText: orderColumns.buyerFeedbackType },
      { columnType: ColumnType.String, fieldName: 'buyerFeedbackText', headerText: orderColumns.buyerFeedbackText },

      { columnType: ColumnType.DateTime, fieldName: 'sellerFeedbackTime', headerText: orderColumns.sellerFeedbackTime },
      { columnType: ColumnType.String, fieldName: 'sellerFeedbackType', headerText: orderColumns.sellerFeedbackType },
      { columnType: ColumnType.String, fieldName: 'sellerFeedbackText', headerText: orderColumns.sellerFeedbackText },

      { columnType: ColumnType.Currency, fieldName: 'purchaseNet', headerText: orderColumns.purchaseNet },
    ],
    [orderColumns],
  );

  return {
    columnInfo,
  };
}
