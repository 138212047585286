import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable, OrderFilterModel, OrderTableRow } from '@/Types';
import { orderMapping } from '@/Mapping';
import { AddressValidationResultType, DateFormatType, OrderStatusFilter } from '@/Enums';
import { shippingLabelModalAsyncActions } from '@/ModalWindows/ShippingLabelModal/services';
import { salesAsyncActions } from './asyncActions';
import { splitOrdersModalAsyncActions } from '@/ModalWindows/SplitOrdersModal/services/asyncActions';
import { mergeOrdersAsyncActions } from '@/ModalWindows/MergeOrdersModal/services/asyncActions';
import { orderTableRowUtils } from '@/Types/OrderTableRowUtils';
import { ModalDataType } from '@/Types/ModalDataType';
import { AddressModel, OrderModel } from '@/Models';
import { dateTimeUtils } from '@/Utils';
import { OrderNoteType } from '@/Enums/OrderNoteType';
import { salesUtils } from '@/Pages/Sales/services/utils';

type NoteModalType = ModalDataType<{
  saleId: number;
  noteType: OrderNoteType;
  noteValue: string;
}>;

export type SalesPageState = {
  sales: OrderTableRow[];
  totalSalesCount: number;
  selectedSale: Nullable<OrderTableRow>;
  selectedSales: OrderTableRow[];
  orderFilters: Nullable<OrderFilterModel>;
  tableStateKey: string;

  modals: {
    orderFormModalData: ModalDataType<{ order: Nullable<OrderTableRow>; modalType: 'create' | 'update' | 'copy' }>;
    orderFormPreviewData: Nullable<OrderTableRow>;
    cantChangeOrderDialog: ModalDataType<null>;
    deleteOrderDialog: ModalDataType<number>;
    copyOrderModal: ModalDataType<number>;
    noteModalData: NoteModalType;
    historyModalData: ModalDataType<null>;
  };
  ordersUpdate: {
    newSalesCount: number;
    updatedOrdersExists: boolean;
  };
  steps: {
    overviewTabActiveStep: number;
    addressTabActiveStep: number;
  };
};

const initialState: SalesPageState = {
  sales: [],
  totalSalesCount: 0,
  selectedSale: null,
  selectedSales: [],
  orderFilters: null,
  tableStateKey: '',

  modals: {
    orderFormModalData: { visible: false },
    orderFormPreviewData: null,
    cantChangeOrderDialog: { visible: false },
    deleteOrderDialog: { visible: false },
    copyOrderModal: { visible: false },
    noteModalData: { visible: false },
    historyModalData: { visible: false },
  },
  ordersUpdate: {
    updatedOrdersExists: false,
    newSalesCount: 0,
  },
  steps: {
    overviewTabActiveStep: 0,
    addressTabActiveStep: 0,
  },
};

const salesPageSlice = createSlice({
  name: 'salesPage',
  initialState,
  reducers: {
    setHistoryModalData: (state, action: PayloadAction<ModalDataType<null>>) => {
      state.modals.historyModalData = action.payload;
    },
    setNoteModalData: (state, action: PayloadAction<NoteModalType>) => {
      state.modals.noteModalData = action.payload;
    },
    setOverviewTabActiveStep: (state, action: PayloadAction<number>) => {
      state.steps.overviewTabActiveStep = action.payload;
    },
    setAddressTabActiveStep: (state, action: PayloadAction<number>) => {
      state.steps.addressTabActiveStep = action.payload;
    },
    setSelectedSale: (state, action: PayloadAction<Nullable<number>>) => {
      state.selectedSale = state.sales.find((sale) => sale.id === action.payload) ?? null;
    },
    setSelectedSales: (state, action: PayloadAction<OrderTableRow[]>) => {
      state.selectedSales = action.payload;
    },
    setOrderFilters: (state, action: PayloadAction<OrderFilterModel>) => {
      state.orderFilters = action.payload;
    },
    setTableStateKey: (state, action: PayloadAction<string>) => {
      state.tableStateKey = action.payload;
    },
    showOrderModal: (
      state,
      action: PayloadAction<{ modalType: 'create' | 'update' | 'copy'; order?: OrderTableRow }>,
    ) => {
      const { order, modalType } = action.payload;

      if (state.selectedSale?.currentInvoiceNumber && modalType === 'update') {
        state.modals.cantChangeOrderDialog = { visible: true };
        return;
      } // if

      let selectedSale: Nullable<OrderTableRow> = null;

      if (modalType === 'update') {
        selectedSale = state.selectedSale;
      } // if

      state.modals.orderFormModalData = {
        visible: true,
        data: {
          order: order ?? selectedSale,
          modalType: modalType,
        },
      };
    },
    closeOrderModal: (state) => {
      state.modals.orderFormModalData = { visible: false };
    },
    closeCantChangeOrderDialog: (state) => {
      state.modals.cantChangeOrderDialog = { visible: false };
    },
    setDeleteOrderDialog: (state, action: PayloadAction<ModalDataType<number>>) => {
      state.modals.deleteOrderDialog = action.payload;
    },
    setCopyOrderModal: (state, action: PayloadAction<boolean>) => {
      state.modals.copyOrderModal = { visible: action.payload };
    },
    setNewSalesCount: (state, action: PayloadAction<number>) => {
      state.ordersUpdate.newSalesCount = action.payload;
    },
    addNewSalesCount: (state, action: PayloadAction<number>) => {
      state.ordersUpdate.newSalesCount += action.payload;
    },
    setUpdatedOrdersExists: (state) => {
      state.ordersUpdate.updatedOrdersExists = true;
    },
    orderUpdate: (state, action: PayloadAction<OrderTableRow[]>) => {
      state.sales = [...action.payload, ...state.sales.filter((x) => x.id !== action.payload[0].id)];
    },
    ordersUpdate: (state, action: PayloadAction<OrderModel[]>) => {
      const updatedOrders = action.payload;
      const updatedOrderIds = updatedOrders.map((x) => x.id);
      const updatedOrderRows = orderMapping.convertOrdersToOrderTableRows(updatedOrders);

      const updateOrderRow = (orderRow: OrderTableRow) => {
        const updatedOrderRow = updatedOrderRows.find((updatedOrder) => updatedOrder.id == orderRow.id);
        return updatedOrderRow ?? orderRow;
      };

      state.sales = state.sales.map(updateOrderRow);
      state.selectedSales = state.selectedSales.map(updateOrderRow);

      if (state.selectedSale?.id && updatedOrderIds.includes(state.selectedSale?.id)) {
        state.selectedSale = orderMapping.convertOrderToOrderTableRows(
          updatedOrders.find((x) => x.id === state.selectedSale!.id)!,
        )?.[0];
      }
    },

    updateValidatedAddress: (
      state: SalesPageState,
      action: PayloadAction<{ orderId: number; address: AddressModel }>,
    ) => {
      const { address, orderId } = action.payload;

      const updateAddress = (order: Nullable<OrderTableRow>) => {
        if (order?.id === orderId) {
          salesUtils.updateShippingAddress(order, address);
        }
      };

      updateAddress(state.selectedSale);
      state.sales.forEach(updateAddress);
      state.selectedSales.forEach(updateAddress);
    },

    removeOrdersFromStateByCurrentFilters: (
      state: SalesPageState,
      action: PayloadAction<{ filters: readonly OrderStatusFilter[]; orderIds: number[] }>,
    ) => {
      const { filters, orderIds } = action.payload;

      if (state.orderFilters && filters.includes(state.orderFilters.orderStatusFilter)) {
        state.sales = state.sales.filter((sale) => !orderIds.includes(sale.id));
        state.selectedSales = state.selectedSales.filter((sale) => !orderIds.includes(sale.id));

        if (state.selectedSale && orderIds.includes(state.selectedSale.id)) {
          state.selectedSale = null;
        }
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(salesAsyncActions.getSalesFromElastic.fulfilled, (state, action) => {
      state.sales = orderMapping.convertOrdersToOrderTableRows(action.payload.items);
      state.totalSalesCount = action.payload.totalEntriesCount;
      state.ordersUpdate = { newSalesCount: 0, updatedOrdersExists: false };

      if (state.selectedSale) {
        state.selectedSale = state.sales.find((x) => x.id === state.selectedSale?.id) ?? null;
      }
      if (state.selectedSales.length) {
        state.selectedSales = state.selectedSales
          .map((selectedSale) => state.sales.find((s) => s.id === selectedSale.id))
          .filter((selectedSale): selectedSale is OrderTableRow => !!selectedSale);
      }
    });

    builder.addCase(shippingLabelModalAsyncActions.applyAddressChanges.fulfilled, (state, action) => {
      const changedOrder = action.payload.order;
      state.sales = state.sales.map((s) =>
        s.id === action.payload.order.id ? orderMapping.copyShippingInfo(s, changedOrder) : s,
      );
      state.selectedSales = state.selectedSales.map((s) =>
        s.id === action.payload.order.id ? orderMapping.copyShippingInfo(s, changedOrder) : s,
      );
      if (state.selectedSale && state.selectedSale.mpOrderNumber === changedOrder.mpOrderNumber) {
        state.selectedSale = orderMapping.copyShippingInfo(state.selectedSale, changedOrder);
      }
    });

    builder.addCase(shippingLabelModalAsyncActions.validateOrderAddresses.fulfilled, (state, action) => {
      const filteredAddresses = action.payload.compares.filter(
        (c) => c.result === AddressValidationResultType.FoundWithFullCompliance,
      );

      state.sales = state.sales.map((s) => {
        const model = filteredAddresses.find((f) => f.orderId === s.id);
        return model ? orderMapping.fillOrderRowWithAddress(s, model.suggestedAddress) : s;
      });

      const updateAddress = (order: OrderTableRow) => {
        const match = filteredAddresses.find((f) => f.orderId === order.id);
        return match
          ? {
              ...order,
              isOriginalShippingModified: true,
              ...orderMapping.toShippingInfo(match.suggestedAddress),
            }
          : order;
      };

      state.selectedSales = state.selectedSales.map(updateAddress);

      if (state.selectedSale) {
        state.selectedSale = updateAddress(state.selectedSale);
      }
    });

    builder.addCase(salesAsyncActions.createOrder.fulfilled, (state, action) => {
      handleOrderCreation(state, action.payload);
    });

    builder.addCase(salesAsyncActions.copyOrder.fulfilled, (state, action) => {
      handleOrderCreation(state, action.payload);
    });

    builder.addCase(salesAsyncActions.updateOrder.fulfilled, (state, action) => {
      state.modals.orderFormModalData = { visible: false };
      handleOrdersUpdate(state, [action.payload]);
    });

    builder.addCase(splitOrdersModalAsyncActions.splitOrder.fulfilled, (state, action) => {
      const { newOrders, splittedOrderId } = action.payload;
      const newOrderRows = orderMapping.convertOrdersToOrderTableRows(newOrders);

      state.sales = [...newOrderRows, ...state.sales.filter((sale) => sale.id !== splittedOrderId)];
    });

    builder.addCase(mergeOrdersAsyncActions.mergeOrders.fulfilled, (state, action) => {
      const { mergedOrder, mergeOrdersIds } = action.payload;
      const newOrderRows = orderMapping.convertOrderToOrderTableRows(mergedOrder);

      state.sales = [...newOrderRows, ...state.sales.filter((sale) => !mergeOrdersIds.some((id) => id === sale.id))];
    });

    builder.addCase(salesAsyncActions.getOrdersByIds.fulfilled, (state, action) => {
      handleOrdersUpdate(state, action.payload);
    });

    builder.addCase(salesAsyncActions.updateOrderNote.fulfilled, (state, action) => {
      handleOrdersUpdate(state, [action.payload.order]);
    });

    builder.addMatcher(
      (action) =>
        [
          salesAsyncActions.createOrderPreview.fulfilled.type,
          salesAsyncActions.updateOrderPreview.fulfilled.type,
          salesAsyncActions.copyOrderPreview.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.modals.orderFormPreviewData = convertOrderPreviewData(action.payload);
      },
    );
  },
});

const handleOrderCreation = (state: SalesPageState, orderModel: OrderModel) => {
  state.modals.orderFormModalData = { visible: false };
  const orderTableRows = orderMapping.convertOrdersToOrderTableRows([orderModel]);

  state.selectedSales = orderTableRows;
  state.selectedSale = orderTableRows[0];
  state.sales = [...orderTableRows, ...state.sales];
};

const handleOrdersUpdate = (state: SalesPageState, updatedOrders: OrderModel[]) => {
  const { selectedOrder, orderRows, selectedOrders } = orderTableRowUtils.updateOrderTableRows(
    {
      selectedOrder: state.selectedSale,
      orderRows: state.sales,
      selectedOrders: state.selectedSales,
    },
    updatedOrders,
  );

  state.selectedSale = selectedOrder;
  state.sales = orderRows;
  state.selectedSales = selectedOrders;
};

const convertOrderPreviewData = (order: OrderModel) => {
  const previewData = orderMapping.convertOrderToOrderTableRows(order)[0];

  return {
    ...previewData,
    paymentDueDate: dateTimeUtils.changeDateFormat(
      previewData?.paymentDueDate,
      DateFormatType.SERVER_DATE_TIME,
      DateFormatType.LOCAL_DATE,
    ),
  };
};

export const salesPageReducer = salesPageSlice.reducer;
export const salesPageActions = salesPageSlice.actions;
export const salesPageAsyncActions = salesAsyncActions;
