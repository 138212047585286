import React from 'react';
import { useDispatch } from 'react-redux';
import { DataTable } from '@/Components/DataTable/DataTable';
import { DocumentTemplateRow } from '@/Types';
import { useDocumentPrintTableColumnInfo } from './ColumnInfo';
import { documentPrintModalActions, SetCountCopy, SetIsSortWhenPrinting } from './services/reducer';

type Props = {
  rows: DocumentTemplateRow[];
};

export function DocumentPrintTableComponent({ rows }: Props): React.ReactElement {
  const dispatch = useDispatch();

  function onCountCopyChanged(row: DocumentTemplateRow, value: number) {
    const setCountCopy: SetCountCopy = {
      guid: row.guid,
      count: value,
    };
    dispatch(documentPrintModalActions.setCountCopy(setCountCopy));
  }

  function onIsSortChanged(row: DocumentTemplateRow, value: boolean) {
    const setIsSortWhenPrinting: SetIsSortWhenPrinting = {
      guid: row.guid,
      isSort: value,
    };
    dispatch(documentPrintModalActions.setIsSortWhenPrinting(setIsSortWhenPrinting));
  }

  const columnInfos = useDocumentPrintTableColumnInfo(onCountCopyChanged, onIsSortChanged);

  return (
    <DataTable<DocumentTemplateRow>
      rows={rows}
      rowKey={'guid'}
      columnInfos={columnInfos}
      filterOptions={{
        needShowFiltersButton: false,
        needHeaderFilter: false,
        needColumnChooser: false,
        showFilterBuilder: false,
        needGlobalSearchPanel: false,
        needClearFiltersButton: false,
      }}
      editOptions={{
        needEditing: false,
      }}
      selectOptions={{
        selectMode: 'single',
        onSelectedRowsChanged(data) {
          dispatch(documentPrintModalActions.setSelectedTemplate(data[0]));
        },
      }}
      defaultOptions={{
        showPagination: false,
      }}
      exportOptions={{
        needExport: false,
        exportFileName: '',
      }}
    />
  );
}

export const DocumentPrintTable = React.memo(DocumentPrintTableComponent);
