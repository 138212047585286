import { OrderModel } from '@/Models';
import { ApiRoutingUtils } from '@/Routing';
import {
  GetOrdersParams,
  OrderLabelsRequest,
  OrderLabelsResponse,
  SynchronizeOrdersRequest,
  SynchronizeOrdersResponse,
  GenerateNumberRequest,
} from './';
import { CreateOrUpdateOrderModel } from '@/Models/CreateOrUpdateOrderModel';
import { rest } from '@/Api/Rest';
import { SplitOrderRequest } from '@/Api/MpOrders/Requests/SplitOrderRequest';
import { MergeOrdersRequest } from '@/Api/MpOrders/Requests/MergeOrdersRequest';
import { MergeOrdersResponse } from '@/Api/MpOrders/Responses/MergeOrdersResponse';
import { SplitOrderResponse } from '@/Api/MpOrders/Responses/SplitOrderResponse';
import { UpdateOrderNoteRequest } from './Requests/UpdateOrderNoteRequest';
import { UpdateOrderNoteResponse } from './Responses/UpdateOrderNoteResponse';
import { UpdateOrdersByFileRequest } from './Requests/UpdateOrdersByFileRequest';
import { UpdateOrdersByFileResponse } from './Responses/UpdateOrdersByFileResponse';
import { GetOrdersAutomatizationRequest } from './Requests/GetOrdersAutomatizationRequest';
import { GetOrdersAutomatizationResponse } from './Responses/GetOrdersAutomatizationResponse';
import { GetWorkdaysCountByDatesRequest } from './Requests/GetWorkdaysCountByDatesRequest';
import { GetNearestWorkdayByWorkdaysCountRequest } from './Requests/GetNearestWorkdayByWorkdaysCountRequest';
import { GetNearestWorkdayByWorkdaysCountResponse } from './Responses/GetNearestWorkdayByWorkdaysCountResponse';
import { GetWorkdaysCountByDatesResponse } from './Responses/GetWorkdaysCountByDatesResponse';
import { CopyOrderRequest } from '@/Api/MpOrders/Requests/CopyOrderRequest';
import { SynchShippingStatusForOrdersParameters } from './SynchShippingStatusesForOrdersParameters';
import { SynchShippingStatusForOrderParameters } from './SynchShippingStatusForOrderParameters';
import { CheckGeneratedNumberForDuplicateRequest } from './Requests/CheckGeneratedNumberForDuplicateRequest';
import { CheckGeneratedNumberForDuplicateResponse } from './Responses/CheckGeneratedNumberForDuplicateResponse';
import { CopyOrderPreviewRequest } from '@/Api/MpOrders/Requests/CopyOrderPreviewRequest';
import { PageableRequest, PageableResponse } from '@/Api';

const url = ApiRoutingUtils.createUrl('mp-orders');

async function getOrders(request: GetOrdersParams): Promise<OrderModel[]> {
  return rest.get(`${url}`, request);
}

async function getOrdersFromElastic(request: PageableRequest<GetOrdersParams>): Promise<PageableResponse<OrderModel>> {
  return rest.get(`${url}/orders-from-elastic`, request);
}

async function synchOrders(request: SynchronizeOrdersRequest): Promise<SynchronizeOrdersResponse> {
  return rest.post(`${url}/synch`, request);
}

async function getLabelInfoRequest(request: OrderLabelsRequest): Promise<OrderLabelsResponse> {
  return rest.get(`${url}/labels-info`, request);
}

async function createOrder(request: CreateOrUpdateOrderModel): Promise<OrderModel> {
  return rest.put(`${url}/create-order`, request);
}

async function updateOrder(request: CreateOrUpdateOrderModel): Promise<OrderModel> {
  return rest.put(`${url}/update-order`, request);
}

async function createOrderPreview(request: CreateOrUpdateOrderModel): Promise<OrderModel> {
  return rest.put(`${url}/create-order-preview`, request);
}

async function updateOrderPreview(request: CreateOrUpdateOrderModel): Promise<OrderModel> {
  return rest.put(`${url}/update-order-preview`, request);
}

async function copyOrderPreview(request: CopyOrderPreviewRequest): Promise<OrderModel> {
  return rest.put(`${url}/copy-order-preview`, request);
}

async function copyOrder(request: CopyOrderRequest): Promise<OrderModel> {
  return rest.put(`${url}/copy-order`, request);
}

async function generateNumberForOrder(request: GenerateNumberRequest): Promise<void> {
  return rest.post(`${url}/generate-number`, request);
}

async function synchShippingsStatusesForOrders(
  parameters: SynchShippingStatusForOrdersParameters,
): Promise<SynchShippingStatusForOrdersParameters> {
  return rest.get(`${url}/synch-shippingStatuses-for-orders`, parameters);
}

async function synchShippingsStatusForOrder(parameters: SynchShippingStatusForOrderParameters): Promise<void> {
  return rest.get(`${url}/synch-shippingStatus-for-order`, parameters);
}

async function checkGeneratedNumberForOrder(
  request: CheckGeneratedNumberForDuplicateRequest,
): Promise<CheckGeneratedNumberForDuplicateResponse> {
  return rest.post(`${url}/check-generated-number`, request);
}

async function splitOrder(orderId: number, splitOrderRequest: SplitOrderRequest): Promise<SplitOrderResponse> {
  return rest.post(`${url}/${orderId}/split`, splitOrderRequest);
}

async function mergeOrders(request: MergeOrdersRequest): Promise<MergeOrdersResponse> {
  return rest.post(`${url}/merge`, request);
}

async function updateOrderNote(request: UpdateOrderNoteRequest): Promise<UpdateOrderNoteResponse> {
  return rest.post(`${url}/update-note`, request);
}

async function deleteOrders(orderIds: number[]): Promise<void> {
  return rest.delete(`${url}`, orderIds);
}

async function updateOrdersByFile(request: UpdateOrdersByFileRequest): Promise<UpdateOrdersByFileResponse> {
  return rest.post(`${url}/update-orders-by-file`, request);
}

async function getOrdersAutomatization(
  request: GetOrdersAutomatizationRequest,
): Promise<GetOrdersAutomatizationResponse> {
  return rest.get(`${url}/get-orders-automatization`, request);
}

async function getNearestWorkdayByWorkdaysCount(
  request: GetNearestWorkdayByWorkdaysCountRequest,
): Promise<GetNearestWorkdayByWorkdaysCountResponse> {
  return rest.get(`${url}/get-nearest-workday`, request);
}

async function getWorkdaysCountByDates(
  request: GetWorkdaysCountByDatesRequest,
): Promise<GetWorkdaysCountByDatesResponse> {
  return rest.get(`${url}/get-workdays-count`, request);
}

export const MpOrdersApi = {
  getOrders,
  getOrdersFromElastic,
  synchOrders,
  getLabelInfoRequest,
  createOrder,
  updateOrder,
  copyOrder,
  generateNumberForOrder,
  splitOrder,
  mergeOrders,
  updateOrderNote,
  deleteOrders,
  updateOrdersByFile,
  getOrdersAutomatization,
  synchShippingsStatusesForOrders,
  synchShippingsStatusForOrder,
  getNearestWorkdayByWorkdaysCount,
  checkGeneratedNumberForOrder,
  getWorkdaysCountByDates,
  createOrderPreview,
  updateOrderPreview,
  copyOrderPreview,
};
