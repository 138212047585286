import React, { PropsWithChildren } from 'react';
import { ClassNameProps } from '@/Types';
import cn from 'classnames';
import styles from './TabList.scss';

type Props = {
  needPlug?: boolean;
  position?: 'left' | 'right';
} & ClassNameProps;

export const TabList: React.FC<Props> = ({
  className,
  children,
  needPlug = true,
  position = 'left',
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={cn(className, {
        [styles.tabsList]: true,
        [styles.tabsList_width_100]: needPlug,
        [styles.onRight]: position === 'right',
      })}
    >
      {children}
    </div>
  );
};
