import { useCallback } from 'react';
import { ChangedOptionInfo, EventInfo } from 'devextreme/events';
import { SortType } from '@/Components/DataTable/types';

export function useOnOptionChanged<TData>(
  isFirstLoad: React.MutableRefObject<boolean>,
  onRowsExpand: () => void,
  allowRemotePagination?: boolean,
  onPageIndexChange?: (pageIndex: number, pageSize: number) => void,
  onPageSizeChange?: (pageIndex: number, pageSize: number) => void,
  onSearchItem?: (value: string) => void,
  onColumnSort?: (column: keyof TData, ascSort: boolean) => void,
) {
  return useCallback(
    (e: ChangedOptionInfo & EventInfo<any>) => {
      if (e.fullName.startsWith('paging')) {
        setTimeout(() => {
          isFirstLoad.current = false;
        }, 0);
      }

      if (e.fullName.startsWith('columns[')) {
        const match = e.fullName.match(/columns\[(\d+)]\.sortOrder/);

        if (match) {
          const columnIndex = parseInt(match[1], 10);
          const columnName = e.component.option(`columns[${columnIndex}].dataField`);

          onColumnSort?.(columnName, e.value == SortType.Asc);
        }
      }

      if (e.fullName === 'paging.pageIndex') {
        onRowsExpand();

        if (allowRemotePagination && !isFirstLoad.current) {
          const pageSize = e.component.option('paging.pageSize');
          onPageIndexChange?.(e.value, pageSize);
        }
      }

      if (e.fullName === 'paging.pageSize') {
        onRowsExpand();

        if (allowRemotePagination && !isFirstLoad.current) {
          onPageSizeChange?.(0, e.value);
          e.component.option('paging.pageIndex', 0);
        }
      }

      if (e.fullName == 'searchPanel.text') {
        onSearchItem?.(e.value);
      }
    },
    [isFirstLoad],
  );
}
