import React, { useMemo } from 'react';
import { Icon } from '@/Components/Icon/Icon';
import { useLocalization } from '@/Hooks';
import { ColumnInfo, ColumnType, OrderAutomatizaionRow } from '@/Types';
import { OrderItemsCell } from './modules/OrderItemsCell/OrderItemsCell';
import { OrderPackagesCell } from './modules/OrderPackagesCell/OrderPackagesCell';
import { countryTypeUtils } from '@/Utils/CountryTypesUtils';
import { CountryCode } from '@/Enums';
import { useSelector } from 'react-redux';
import { globalSelectors } from '@/Redux/Global/selectors';
import styles from './OrderAutomatizationTable.scss';

export const useOrderAutomatizationTableColumns = () => {
  const {
    modalWindows: { orderLinkWithProduct },
  } = useLocalization();

  const translation = useSelector(globalSelectors.translation);

  return useMemo((): ColumnInfo<OrderAutomatizaionRow>[] => {
    return [
      {
        headerText: orderLinkWithProduct.Header.Market,
        fieldName: 'marketplaceImage',
        columnType: ColumnType.String,
        alignment: 'center',
        render: (rowData) => {
          return (
            <div className={styles.logoBlock}>
              <Icon alt={''} src={rowData.marketplaceImage} className={styles.marketLogo} />
            </div>
          );
        },
      },
      {
        headerText: orderLinkWithProduct.Header.ID,
        fieldName: 'userOrderId',
        columnType: ColumnType.String,
      },
      {
        headerText: orderLinkWithProduct.Header.Invoice,
        fieldName: 'invoice',
        columnType: ColumnType.String,
      },
      {
        headerText: orderLinkWithProduct.Header.Items,
        fieldName: 'orderItems',
        columnType: ColumnType.String,
        render: (rowData) => {
          return <OrderItemsCell items={rowData.orderItems} />;
        },
      },
      {
        headerText: orderLinkWithProduct.Header.Amount,
        fieldName: 'amount',
        columnType: ColumnType.Currency,
      },
      {
        headerText: orderLinkWithProduct.Header.Country,
        fieldName: 'shippingCountryName',
        columnType: ColumnType.String,
        render: ({ shippingCountryCode }) => (
          <span>{countryTypeUtils.getCountryNameByCountryCode(shippingCountryCode as CountryCode, translation)}</span>
        ),
      },
      {
        headerText: orderLinkWithProduct.Header.OriginalShippingMethod,
        fieldName: 'originalShippingMethod',
        columnType: ColumnType.String,
      },
      {
        headerText: orderLinkWithProduct.Header.NewShippingMethod,
        fieldName: 'selectedPackages',
        columnType: ColumnType.String,
        render: (rowData, rowIndex) => {
          return <OrderPackagesCell rowData={rowData} rowIndex={rowIndex} />;
        },
      },
    ];
  }, [orderLinkWithProduct]);
};
