import React from 'react';
import { Icon } from '@/Components/Icon/Icon';
import { Icons } from '@/Static/IconsRes';
import Button from 'devextreme-react/button';
import styles from './TableImportButton.scss';

export const TableImportButton = (): React.ReactElement => {
  return (
    <Button>
      <Icon alt="clearFiltersIcon" src={Icons.Incoming} className={styles.icon} />
    </Button>
  );
};
