import React, { useCallback } from 'react';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import FileSaver from 'file-saver';
import { DataGrid } from 'devextreme-react/data-grid';

export const useOnExporting = <TData>(
  innerGridRef: React.MutableRefObject<DataGrid<TData, keyof TData> | undefined>,
  exportFileName: string,
  exportWorksheetName?: string,
) => {
  const onExporting = useCallback(
    (onlySelected = false) => {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(exportWorksheetName);

      const instance = innerGridRef.current?.instance;

      exportDataGrid({ component: instance, worksheet: worksheet, selectedRowsOnly: onlySelected }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), exportFileName);
        });
      });
    },
    [exportFileName, exportWorksheetName],
  );

  return {
    onExporting,
  };
};
