import { IconColor, Nullable, OrderTableRow } from '@/Types';
import { FeedbackRatingType } from '@/Enums';
import { useMemo } from 'react';

export const useOrderStatusIcons = (purchase: Nullable<OrderTableRow>) => {
  const paymentIconInfo = useMemo(() => {
    if (!purchase) {
      return {
        color: IconColor.Grey,
        tooltipText: '',
      };
    }

    const orderPaid = purchase.amountPaid > 0;
    const paymentRefund = purchase.refundAmount < 0;

    if (!orderPaid && !paymentRefund) {
      return {
        color: IconColor.Grey,
        tooltipText: 'Order not paid',
      };
    }

    if (orderPaid && paymentRefund) {
      return {
        color: IconColor.Orange,
        tooltipText: `Order paid on ${purchase.paidTime} and partial refunded on ${purchase.refundTime}`,
      };
    }

    if (!orderPaid && paymentRefund) {
      return {
        color: IconColor.Red,
        tooltipText: `Order paid on ${purchase.paidTime} and refunded on ${purchase.refundTime}`,
      };
    }

    if (orderPaid && purchase.amountPaid < purchase.priceBruttoWithShipping) {
      return {
        color: IconColor.Yellow,
        tooltipText: 'Order not paid in full',
      };
    }

    if (orderPaid && purchase.amountPaid === purchase.priceBruttoWithShipping) {
      return {
        color: IconColor.Blue,
        tooltipText: `Order paid on ${purchase.paidTime}`,
      };
    }

    if (orderPaid && purchase.amountPaid > purchase.priceBruttoWithShipping) {
      return {
        color: IconColor.Lila,
        tooltipText: `Order overpaid on ${purchase.paidTime}`,
      };
    }

    return {
      color: IconColor.Grey,
      tooltipText: 'Order not paid',
    };
  }, [purchase]);

  const shippingIconInfo = useMemo(() => {
    if (!purchase) {
      return {
        color: IconColor.Grey,
        tooltipText: '',
      };
    }

    if (!purchase?.shippedTime) {
      return {
        color: IconColor.Grey,
        tooltipText: 'Not shipped',
      };
    }

    return {
      color: IconColor.Blue,
      tooltipText: `Shipped on ${purchase?.shippedTime}`,
    };
  }, [purchase]);

  const sellerFeedbackIconInfo = useMemo(() => {
    if (!purchase) {
      return {
        color: IconColor.Grey,
        tooltipText: '',
      };
    }

    if (!purchase?.sellerFeedbackTime) {
      return {
        color: IconColor.Grey,
        tooltipText: 'Not submitted Feedback',
      };
    }

    return {
      color: IconColor.Blue,
      tooltipText: `Feedback submitted on ${purchase.sellerFeedbackTime}`,
    };
  }, [purchase]);

  const buyerFeedbackIconInfo = useMemo(() => {
    if (!purchase) {
      return {
        color: IconColor.Grey,
        tooltipText: '',
      };
    }

    if (!purchase?.buyerFeedbackTime) {
      return {
        color: IconColor.Grey,
        tooltipText: 'Not received Feedback',
      };
    }

    if (purchase?.buyerFeedbackTime && purchase?.buyerFeedbackType == FeedbackRatingType.Positive) {
      return {
        color: IconColor.Blue,
        tooltipText: `Feedbach received on ${purchase?.buyerFeedbackTime}`,
      };
    }

    if (purchase?.buyerFeedbackTime && purchase?.buyerFeedbackType == FeedbackRatingType.Neutral) {
      return {
        color: IconColor.Yellow,
        tooltipText: `Feedbach received on ${purchase?.buyerFeedbackTime}`,
      };
    }

    if (purchase?.buyerFeedbackTime && purchase?.buyerFeedbackType == FeedbackRatingType.Negative) {
      return {
        color: IconColor.Red,
        tooltipText: `Feedbach received on ${purchase?.buyerFeedbackTime}`,
      };
    }

    return {
      color: IconColor.Grey,
      tooltipText: 'Not received Feedback',
    };
  }, [purchase]);

  return {
    paymentIconInfo,
    shippingIconInfo,
    sellerFeedbackIconInfo,
    buyerFeedbackIconInfo,
  };
};
