import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@/Redux/RootReducer';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { AppNotification, NotificationCode, NotificationType } from '@/SignalR/Data';
import { localizationUtils } from '@/Utils/localizationUtils';
import { Toasts } from '@/Components/Toast/Toast';
import { globalSelectors } from '@/Redux/Global/selectors';
import {
  marketplacesSettingsActions,
  marketplacesSettingsAsyncActions,
} from '@/Pages/Settings/modules/MarketplacesSettings/services/reducer';
import { backgroundTasksActions } from '@/Redux/BackgroundTasks/reducer';
import { BackgroundTask } from '@/Models/BackgroundTask';
import { BackgroundTaskProgress } from '@/Models/BackgroundTaskProgress';
import { backgroundTaskMapping } from '@/Mapping/backgroundTaskMapping';
import { LogUtil } from '@/Utils';
import { userSelectors } from '@/Redux/User';
import { AppUserSettingNameType, ModalType } from '@/Enums';
import { SynchronizeType } from '@/Enums/SynchronizeType';
import { synchOrdersCompleteModalActions } from '@/ModalWindows/SynchOrdersCompleteModal/services/reducer';
import { modalWindowsActions } from '@/ModalWindows/services';
import { salesPageActions, salesPageAsyncActions } from '@/Pages/Sales/services';
import { purchasesPageActions, purchasesPageAsyncActions } from '@/Pages/Purchases/services';
import { WebNotificationHub } from '@/SignalR/Hubs/WebNotificationHub';

const COUNT_OF_NEW_SALES_PARAMS_INDEX = 0;
const COUNT_OF_UPDATED_SALES_PARAMS_INDEX = 2;
const COUNT_OF_NEW_PURCHASES_PARAMS_INDEX = 4;
const COUNT_OF_UPDATED_PURCHASES_PARAMS_INDEX = 6;

export class NotificationSubscriptionInitializer {
  private static instance: NotificationSubscriptionInitializer;
  private _dispatch!: ThunkDispatch<RootState, unknown, AnyAction>;
  private getState!: () => RootState;
  private _taskHub!: WebNotificationHub;
  private changeDeliveryNotificationTimer: NodeJS.Timer | undefined;

  constructor(dispatch: AppDispatch, getState: () => RootState) {
    if (NotificationSubscriptionInitializer.instance) return NotificationSubscriptionInitializer.instance;

    NotificationSubscriptionInitializer.instance = this;

    this._dispatch = dispatch;
    this.getState = getState;
    this._taskHub = new WebNotificationHub();
  }

  public initialize() {
    this._taskHub.onNewNotification(this.onNewNotification);
    this._taskHub.onNewBackgroundTask(this.onNewBackgroundTask);
    this._taskHub.onUpdateBackgroundTask(this.onUpdateBackgroundTask);
    this._taskHub.onFinishBackgroundTask(this.onFinishBackgroundTask);
  }

  private onNewNotification = (notification: AppNotification) => {
    this.notificationHandleToast(notification);
    this.notificationHandleDispatchAction(notification);
  };

  private onNewBackgroundTask = (task: BackgroundTask) => {
    this._dispatch(backgroundTasksActions.addTask(backgroundTaskMapping.toBackgroundTaskForProgressList(task)));
  };

  private onUpdateBackgroundTask = (taskProgress: BackgroundTaskProgress) => {
    this._dispatch(backgroundTasksActions.updateTask(taskProgress));
  };

  private onFinishBackgroundTask = (taskId: number) => {
    this._dispatch(backgroundTasksActions.finishTask(taskId));
  };

  private isOrdersSyncNotification = (notification: AppNotification) => {
    return [
      NotificationCode.SynchOrdersComplete,
      NotificationCode.SynchEmptyPurchasesComplete,
      NotificationCode.SynchEmptyOrdersComplete,
      NotificationCode.SynchEmptySalesComplete,
    ].includes(notification.notificationCode as NotificationCode);
  };

  private notificationHandleDispatchAction(notification: AppNotification) {
    if (this.isOrdersSyncNotification(notification)) {
      this.handleOrdersSynchronization(notification);
    } else {
      this.handleMarketplaceAccountNotification(notification);
    }
  }

  private notificationHandleToast(notification: AppNotification) {
    const { title, message, notificationType, notificationCode, params } = notification;

    let _title = title;
    let _message = message ?? '';
    if (notificationCode != null) {
      const res = this.getNotificationText(notificationCode, params);
      _title = res.title;
      _message = res.text;
    }

    switch (notificationType) {
      case NotificationType.Info:
        Toasts.showInfo({ title: _title, text: _message });
        break;
      case NotificationType.Success:
        Toasts.showSuccess({ title: _title, text: _message });
        break;
      case NotificationType.Error:
        Toasts.showError({ title: _title, text: _message });
        break;
    }
  }

  private getNotificationText = (
    code: string | NotificationCode,
    params: string[] | undefined,
  ): { title: string; text: string } => {
    const translation = globalSelectors.translation(this.getState());
    const localization = globalSelectors.localization(this.getState());

    let notificationTranslation: {
      text: string;
      title: string;
      parts?: { [key: string]: { [key: string]: string } };
    } = { text: '', title: '', parts: {} };
    if (code in translation.Notifications) {
      notificationTranslation = translation.Notifications[code as keyof typeof translation.Notifications];
    }

    if (code in translation.Errors) {
      console.log('code is errors', code);
      notificationTranslation = translation.Errors[code as keyof typeof translation.Errors];
      console.log(notificationTranslation);
    }

    if (!notificationTranslation) {
      LogUtil.LogError(`There is no NotificationCode = ${code} in localization!`);
    }

    const localNotifText = localizationUtils.insertTranslatedParamsToString(
      notificationTranslation.text,
      params || [],
      notificationTranslation.parts,
      localization,
    );

    return { title: notificationTranslation.title, text: localNotifText };
  };

  private handleMarketplaceAccountNotification = (notification: AppNotification) => {
    switch (notification.notificationCode) {
      case NotificationCode.SynchOrdersTokenError:
      case NotificationCode.SynchFeedbacksTokenError: {
        if (notification.params?.length) {
          this._dispatch(marketplacesSettingsAsyncActions.getMpAccounts());
        }
        break;
      }

      case NotificationCode.GetMpAccountTokenSuccess: {
        if (notification.params) {
          this._dispatch(
            marketplacesSettingsActions.setTokenExpirationTime({
              accountId: notification.params[0],
              tokenExpirationTime: notification.params[1],
            }),
          );
        }
        break;
      }

      case NotificationCode.ChangeDeliveryStatusUnhandledError: {
        clearTimeout(this.changeDeliveryNotificationTimer as number | undefined);

        this.changeDeliveryNotificationTimer = setTimeout(() => {
          this._dispatch(salesPageAsyncActions.getSalesFromElastic());
        }, 3000);
      }
    }
  };

  private handleOrdersSynchronization = (notification: AppNotification) => {
    let newSalesCount = 0;
    let updatedSalesCount = 0;
    let newPurchasesCount = 0;
    let updatedPurchasesCount = 0;

    if (notification.params) {
      switch (notification.notificationCode) {
        case NotificationCode.SynchOrdersComplete: {
          newSalesCount = +notification.params[COUNT_OF_NEW_SALES_PARAMS_INDEX];
          updatedSalesCount = +notification.params[COUNT_OF_UPDATED_SALES_PARAMS_INDEX];
          newPurchasesCount = +notification.params[COUNT_OF_NEW_PURCHASES_PARAMS_INDEX];
          updatedPurchasesCount = +notification.params[COUNT_OF_UPDATED_PURCHASES_PARAMS_INDEX];
          break;
        }
        case NotificationCode.SynchEmptyPurchasesComplete: {
          newSalesCount = +notification.params[COUNT_OF_NEW_SALES_PARAMS_INDEX];
          updatedSalesCount = +notification.params[COUNT_OF_UPDATED_SALES_PARAMS_INDEX];
          break;
        }
        case NotificationCode.SynchEmptySalesComplete: {
          newPurchasesCount = +notification.params[COUNT_OF_NEW_PURCHASES_PARAMS_INDEX];
          updatedPurchasesCount = +notification.params[COUNT_OF_UPDATED_PURCHASES_PARAMS_INDEX];
          break;
        }

        default: {
          throw new Error('Not implemented exception');
        }
      } // switch
    }

    const synchronizeSetting = userSelectors.getSetting(this.getState())(AppUserSettingNameType.Synchronize);
    switch (synchronizeSetting?.value) {
      case SynchronizeType.Automatic: {
        this._dispatch(salesPageAsyncActions.getSalesFromElastic());
        this._dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
        break;
      }

      case SynchronizeType.Manual: {
        if (newSalesCount) this._dispatch(salesPageActions.addNewSalesCount(newSalesCount));
        else if (updatedSalesCount) this._dispatch(salesPageActions.setUpdatedOrdersExists());

        if (newPurchasesCount) this._dispatch(purchasesPageActions.addNewPurchasesCount(newPurchasesCount));
        else if (updatedPurchasesCount) this._dispatch(purchasesPageActions.setUpdatedPurchasesExists());

        break;
      }

      case SynchronizeType.AlwaysAsk:
      default: {
        this._dispatch(
          synchOrdersCompleteModalActions.setSalesInfo({
            newSalesCount,
            updatedSalesCount,
          }),
        );

        this._dispatch(
          synchOrdersCompleteModalActions.setPurchasesInfo({
            newPurchasesCount,
            updatedPurchasesCount,
          }),
        );

        this._dispatch(modalWindowsActions.openModal({ modalType: ModalType.SynchOrdersComplete }));
        break;
      }
    } // switch
  };
}
