import { useMemo } from 'react';
import { DocumentPrintOrderType } from '@/Enums/DocumentPrintOrderType';
import { useLocalization } from '@/Hooks';

export const useDocumentPrintOrderTypeOptions = () => {
  const {
    enums: { DocumentPrintOrderType: DocumentPrintOrderTypeTranslation },
  } = useLocalization();

  return useMemo(() => {
    return [
      {
        key: DocumentPrintOrderType.OrderId,
        value: DocumentPrintOrderTypeTranslation.OrderId,
      },
      {
        key: DocumentPrintOrderType.DocumentNumber,
        value: DocumentPrintOrderTypeTranslation.DocumentNumber,
      },
    ];
  }, []);
};
