import { useDocumentPrintTableColumnInfo } from './ColumnInfo';
import { DataTable } from '@/Components/DataTable/DataTable';
import React, { useMemo } from 'react';
import styles from './SelectDocumentModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { documentPrintModalSelectors } from '@/ModalWindows/DocumentPrintModal/services/selectors';
import { documentPrintModalActions } from '@/ModalWindows/DocumentPrintModal/services/reducer';
import { DocumentForPrintModel } from '@/Models/DocumentForPrintModel';

export function SelectDocumentTable() {
  const dispatch = useDispatch();

  const columnInfos = useDocumentPrintTableColumnInfo();
  const documentNumbers = useSelector(documentPrintModalSelectors.documentNumbers);

  return useMemo(
    () => (
      <DataTable<DocumentForPrintModel>
        rows={documentNumbers}
        rowKey={'documentNumber'}
        className={styles.numbersTable}
        columnInfos={columnInfos}
        filterOptions={{
          needShowFiltersButton: false,
          needHeaderFilter: false,
          needColumnChooser: false,
          showFilterBuilder: false,
          needGlobalSearchPanel: false,
          needClearFiltersButton: false,
        }}
        editOptions={{
          needEditing: false,
        }}
        selectOptions={{
          showCheckBoxesMode: 'onClick',
          onSelectedRowsChanged(data) {
            dispatch(documentPrintModalActions.setSelectedDocumentNumbers(data));
          },
        }}
        defaultOptions={{
          showPagination: false,
        }}
        exportOptions={{
          needExport: false,
          exportFileName: '',
        }}
      />
    ),
    [documentNumbers],
  );
}
