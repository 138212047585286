import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import { globalHistory } from '@/GlobalHistory';
import { App } from '@/App';
import { PagesRouting } from '@/Routing';
import { store } from '@/Redux/ConfigureStore';
import { NotificationSubscriptionInitializer } from '@/SignalR/Subscriptions';
import { ErrorHandler } from '@/Api';
import { hot } from 'react-hot-loader';
import { LoadingHandler } from '@/Api/LoadingHandler';
import { ToastContainerWrapper } from './Components/Toast/Toast';
import './Index.scss';
import {
  ChangePasswordPage,
  EmailConfirmationPage,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
} from '@/Pages/Authorization';
import '@/Extensions/NumberExtensions';
import { NumberFormatHandler } from './Utils/services/NumberFormatHandler';
import { OnlineArchiveNotificationSubscriptionInitializer } from '@/SignalR/Subscriptions/OnlineArchiveNotificationHubSubscription';

const taskSubscriptionInitializer = new NotificationSubscriptionInitializer(store.dispatch, store.getState);
const onlineArchiveHubSubscriptionInitializer = new OnlineArchiveNotificationSubscriptionInitializer(store.getState);

taskSubscriptionInitializer.initialize();
onlineArchiveHubSubscriptionInitializer.initialize();
// Singleton services initializing
// Need for correct work
ErrorHandler.start(store.getState);
LoadingHandler.start(store);
NumberFormatHandler.start(store.getState);

let Root = () => {
  return (
    <Provider store={store}>
      <ToastContainerWrapper />
      <Router history={globalHistory}>
        <Switch>
          <Route exact path={PagesRouting.AuthorizationPages.LoginPage} component={SignInPage} />
          <Route exact path={PagesRouting.AuthorizationPages.RegistrationPage} component={SignUpPage} />
          <Route exact path={PagesRouting.AuthorizationPages.EmailConfirmationPage} component={EmailConfirmationPage} />
          <Route exact path={PagesRouting.AuthorizationPages.ResetPasswordPage} component={ResetPasswordPage} />
          <Route exact path={PagesRouting.AuthorizationPages.ChangePasswordPage} component={ChangePasswordPage} />
          <Route path={'/'}>
            <App />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
};

if (module.hot) {
  Root = hot(module)(Root);
}

ReactDOM.render(<Root />, document.getElementById('app'));
