import { TableFilterModel } from '@/Types/TableFilterModel';
import { SortType } from '@/Components/DataTable/types';

function getTableFilterState<T>(stateKey: string): TableFilterModel<T> {
  const item = localStorage.getItem(stateKey);

  if (!item)
    return {
      pageSize: 50,
      currentPage: 0,
    };

  const tableState = JSON.parse(item);
  const sortedFieldIdx = tableState.columns.findIndex((item: any) => item.sortOrder);

  if (sortedFieldIdx != -1) {
    return {
      sortColumn: tableState.columns[sortedFieldIdx].name,
      isAscending: tableState.columns[sortedFieldIdx].sortOrder == SortType.Asc,
      pageSize: tableState.pageSize,
      currentPage: tableState.pageIndex,
    };
  }

  return {
    pageSize: tableState.pageSize,
    currentPage: tableState.pageIndex,
  };
}

export const tableUtils = {
  getTableFilterState,
};
