import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { salesUtils } from 'src/Pages/Sales/services/utils';
import { OrderStatusFilter } from '@/Enums';

const salesPage = (state: RootState) => state.salesPage;

const sales = createSelector([salesPage], (state) => state.sales);

const selectedSale = createSelector(salesPage, (state) => state.selectedSale);

const selectedSales = createSelector(salesPage, (state) => state.selectedSales);

const selectedSalesIds = createSelector(selectedSales, (state) => state.map((sale) => sale.id));

const ordersUpdate = createSelector(salesPage, (state) => state.ordersUpdate);

const orderFilters = createSelector(salesPage, (state) => {
  const searchParams = new URLSearchParams(window.location.search);
  const orderStatusFilter = searchParams.get('status') as OrderStatusFilter;
  const resultFilter = state.orderFilters ?? salesUtils.getDefaultFilters();

  return OrderStatusFilter[orderStatusFilter] ? { ...resultFilter, orderStatusFilter } : resultFilter;
});

const orderFiltersNullable = createSelector(salesPage, (state) => state.orderFilters);

const tableStateKey = createSelector(salesPage, (state) => state.tableStateKey);

const totalSalesCount = createSelector(salesPage, (state) => state.totalSalesCount);

const previewData = createSelector(salesPage, (state) => state.modals.orderFormPreviewData);

const orderFormModalData = createSelector(salesPage, (state) => state.modals.orderFormModalData);

const cantChangeOrderDialog = createSelector(salesPage, (state) => state.modals.cantChangeOrderDialog);

const deleteOrderDialog = createSelector(salesPage, (state) => state.modals.deleteOrderDialog);

const copyOrderModal = createSelector(salesPage, (state) => state.modals.copyOrderModal);

const noteModalData = createSelector(salesPage, (state) => state.modals.noteModalData);

const historyModalData = createSelector(salesPage, (state) => state.modals.historyModalData);

const addressTabActiveStep = createSelector(salesPage, (state) => state.steps.addressTabActiveStep);

const overviewTabActiveStep = createSelector(salesPage, (state) => state.steps.overviewTabActiveStep);

export const salesPageSelectors = {
  sales,
  selectedSale,
  selectedSales,
  orderFilters,
  orderFormModalData,
  cantChangeOrderDialog,
  selectedSalesIds,
  ordersUpdate,
  deleteOrderDialog,
  copyOrderModal,
  orderFiltersNullable,
  previewData,
  addressTabActiveStep,
  overviewTabActiveStep,
  noteModalData,
  historyModalData,
  tableStateKey,
  totalSalesCount,
};
