import { FieldState } from 'src/Types';
import { useLocalization } from 'src/Hooks';
import { useAppRouting } from 'src/Hooks/useAppRouting';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from 'src/Pages/Sales/services';
import { purchasesPageSelectors } from 'src/Pages/Purchases/services';
import { globalSelectors } from 'src/Redux/Global/selectors';
import { useMemo } from 'react';
import { localizationUtils } from 'src/Utils/localizationUtils';
import { orderTableRowUtils } from 'src/Types/OrderTableRowUtils';

export const useOrderNumberTooltipMessage = (): FieldState => {
  const {
    models: {
      OrderTableRow: { OrderNumber, parts, CommonErrors },
    },
  } = useLocalization();
  const { isSalesPage, isPurchasesPage } = useAppRouting();
  const selectedSales = useSelector(salesPageSelectors.selectedSales);
  const selectedPurchases = useSelector(purchasesPageSelectors.selectedPurchases);
  const localization = useSelector(globalSelectors.localization);

  return useMemo(() => {
    const orders = isSalesPage ? selectedSales : selectedPurchases;

    const result = orders.reduce(
      (res, current) => {
        if (current.orderNumberCreatedTime) {
          res.withOrderNumbers.add(current.orderNumber ?? '');
        }

        if (orderTableRowUtils.isOrderIsAmazonPending(current)) {
          res.amazonInProcessOrders.add(current.userOrderId.toString());
        }

        return res;
      },
      {
        withOrderNumbers: new Set<string>(),
        amazonInProcessOrders: new Set<string>(),
      },
    );

    const withOrderNumbers = [...result.withOrderNumbers];
    const amazonInProcessOrders = [...result.amazonInProcessOrders];

    let disabledDocumentMessage: string | undefined;
    const isIconDisabled = Boolean(withOrderNumbers.length || amazonInProcessOrders.length);

    if (withOrderNumbers.length) {
      disabledDocumentMessage = localizationUtils.insertTranslatedParamsToString(
        OrderNumber.DisableHeaderDocumentMessage,
        [withOrderNumbers.join(', '), withOrderNumbers.length],
        parts,
        localization,
      );
    } else if (amazonInProcessOrders.length) {
      disabledDocumentMessage = CommonErrors.AmazonOrderPending.DisableHeaderDocumentMessage;
    } // if

    return {
      value: '',
      isDocumentIconDisabled: isIconDisabled || isPurchasesPage,
      disabledDocumentMessage,
    };
  }, [localization, selectedSales, selectedPurchases, isSalesPage, isPurchasesPage]);
};
