import { DateFormatType, InvoiceType, OrderStatusFilter } from '@/Enums';
import { AddressModel, OrderItemModel } from '@/Models';
import { Nullable, OrderFilterModel, OrderItemProductShortTableRow, OrderTableRow } from '@/Types';
import { DateRangeParamType } from '@/Api';
import moment from 'moment/moment';

const updateShippingAddress = (orderTableRow: Nullable<OrderTableRow>, addressModel: AddressModel) => {
  if (!orderTableRow) {
    return;
  }

  orderTableRow.shippingStreet = addressModel.street;
  orderTableRow.shippingHouseNo = addressModel.houseNo;
  orderTableRow.shippingStreet = addressModel.street;
  orderTableRow.shippingAdditionalLine = addressModel.additionalLine;
  orderTableRow.shippingCityName = addressModel.city;
  orderTableRow.shippingCountryCode = addressModel.countryCode;
  orderTableRow.shippingPostalCode = addressModel.postalCode;
  orderTableRow.shippingCompanyName = addressModel.company;
  orderTableRow.shippingFirstName = addressModel.firstName;
  orderTableRow.shippingLastName = addressModel.lastName;
  orderTableRow.isOriginalShippingModified = true;
};

function calculateProductPrice(productItem: OrderItemProductShortTableRow, invoiceType: InvoiceType): number {
  return +(
    productItem.quantity *
    (invoiceType === InvoiceType.GrossInvoice ? Number(productItem.priceBrutto) : Number(productItem.priceNetto))
  ).toFixed(2);
}

function calculateOrderItemPrice(invoiceType: InvoiceType, orderItem: OrderItemModel): number {
  const price = invoiceType === InvoiceType.GrossInvoice ? Number(orderItem.priceBrutto) : Number(orderItem.priceNetto);
  return orderItem.quantity * price;
}

function calculatePriceBrutto(priceNetto: number, vat: number): number {
  return +(priceNetto * (1 + vat / 100)).toFixed(2);
}

function calculatePriceNetto(priceBrutto: number, vat: number): number {
  return +(priceBrutto / (1 + vat / 100)).toFixed(2);
}

function calculateShippingPrice(order: Nullable<OrderTableRow>, invoiceType: InvoiceType) {
  if (!order) return 0;
  switch (invoiceType) {
    case InvoiceType.GrossInvoice:
      return order.shippingPrice;
    case InvoiceType.NetInvoice:
      return calculatePriceNetto(order.shippingPrice, order.orderItems[0].salesTaxPercent);
    default:
      throw new Error('InvoiceType out of range');
  }
}

function getInvoiceNumbers(order: Nullable<OrderTableRow>): string[] {
  return order?.invoices?.map((inv) => inv.number) ?? [];
}

function getCancelInvoiceNumbers(order: Nullable<OrderTableRow>): string[] {
  return (
    order?.invoices
      ?.map((inv) => inv.orderCancelInvoice)
      .filter((cancelInv) => cancelInv)
      .map((cancelInvoice) => cancelInvoice!.number) ?? []
  );
}

function getDefaultFilters(): OrderFilterModel {
  return {
    mpAccountIds: [],
    orderStatusFilter: OrderStatusFilter.NotShippedAll,
    dateRange: {
      rangeType: DateRangeParamType.CreationTime,
      timeFrom: moment().subtract(30, 'days').startOf('day').format(DateFormatType.LOCAL_DATE_TIME),
      timeTo: moment().endOf('day').format(DateFormatType.LOCAL_DATE_TIME),
    },
    searchString: '',
    searchGroup: undefined,
  };
}

const getIsFilterChanged = (
  initialOrderFilters: OrderFilterModel,
  newOrderFilters: OrderFilterModel & { dateRangeTuple: string },
  needSearch?: boolean,
) => {
  const { mpAccountIds, orderStatusFilter, dateRange, searchString, searchGroup } = initialOrderFilters;
  const { mpAccountIds: newMpAccountIds, orderStatusFilter: newOrderStatusFilter } = newOrderFilters;

  // Date equality
  const timeFrom = newOrderFilters.dateRangeTuple?.split(' - ')[0];
  const timeTo = newOrderFilters.dateRangeTuple?.split(' - ')[1];
  const isDateRangeEqual = dateRange.timeFrom === timeFrom && dateRange.timeTo === timeTo;

  // Mp account equality
  let isMpAccountIdsEqual = mpAccountIds?.length == 0 || mpAccountIds?.length === newMpAccountIds?.length;

  if (isMpAccountIdsEqual && mpAccountIds?.length != 0) {
    for (let i = 0; i < (newMpAccountIds?.length ?? 0); i++) {
      isMpAccountIdsEqual = mpAccountIds?.[i] === newMpAccountIds?.[i];
      if (!isMpAccountIdsEqual) break;
    } // for
  } // if

  // Status equality
  const isOrderStatusEqual = orderStatusFilter === newOrderStatusFilter;

  if (!needSearch) {
    return !isDateRangeEqual || !isMpAccountIdsEqual || !isOrderStatusEqual;
  }

  // Search equality
  const isSearchTextEqual = searchString == newOrderFilters.searchString;

  // Group equality
  const isSearchGroupEqual = Boolean(searchGroup) == Boolean(newOrderFilters.searchGroup);

  return !isDateRangeEqual || !isMpAccountIdsEqual || !isOrderStatusEqual || !isSearchTextEqual || !isSearchGroupEqual;
};

export const salesUtils = {
  calculateProductPrice,
  calculateOrderItemPrice,
  calculateShippingPrice,
  getInvoiceNumbers,
  getCancelInvoiceNumbers,
  calculatePriceBrutto,
  calculatePriceNetto,
  getDefaultFilters,
  getIsFilterChanged,
  updateShippingAddress,
};
