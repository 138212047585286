import { OrderAutomatizationItemModel } from '@/Models/OrderUserProducts/OrderAutomatizationItemModel';
import React, { useEffect, useRef, useState } from 'react';
import styles from './OrderItemsCell.scss';
import { useDebounce } from '@/Hooks/useDebounce';

type Props = {
  items: OrderAutomatizationItemModel[];
};

export const OrderItemsCell = ({ items }: Props) => {
  const variantRef = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const [debounceCallback] = useDebounce((entries: ResizeObserverEntry[]) => {
    const { width } = entries[0].contentRect;

    if (width < 190) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, 100);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debounceCallback);

    if (variantRef.current) {
      resizeObserver.observe(variantRef.current);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  return (
    <>
      {items.map((item) => (
        <div className={styles.orderItemsCell} key={item.id}>
          <span>{`${item.quantity}x`}</span>
          <span ref={variantRef}>
            {showTooltip ? (
              <span title={item.variantTitle}>{item.variantTitle ? `${item.variantTitle?.slice(0, 15)}...` : ''}</span>
            ) : (
              <span>{item.variantTitle}</span>
            )}
          </span>
          <span>{item.priceBrutto.toFixed(2)}</span>
          <span>{item.totalPriceBruttoWithoutShipping.toFixed(2)}</span>
        </div>
      ))}
    </>
  );
};
