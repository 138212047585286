import { Pair } from '@/Types';
import { OrderMarker } from '@/Enums';
import { DocumentTemplateSharingType, DocumentTemplateType } from '@/Enums/DocumentTemplateType';
import { AutomaticallyGeneratedDocumentType } from '@/Enums/AutomaticallyGeneratedDocumentType';

export type DocumentTemplateModel = {
  id: number;
  guid: string;
  name: string;

  documentTemplateSettings: DocumentTemplateSettingsModel;
  emailTemplateSettings?: EmailTemplatesSettingsModel;
  generatingNumberSettings?: GeneratingNumberSettingsModel;

  type: DocumentTemplateType;

  automaticallyGeneratedDocumentType?: AutomaticallyGeneratedDocumentType | string;

  canRestore: boolean;
  documentTemplateSharingType?: DocumentTemplateSharingType;
};

export type GeneratingNumberSettingsModel = {
  countCopy: number;
  isSortWhenPrinting: boolean;
  sendOrderEmail: boolean;
  printDocument: boolean;
};

export type DocumentTemplateSettingsModel = {
  isOnlineArchive: boolean;
  fileName: string;
  separatingSign: string;
  directoryPath: string;
  isSaveWhenSending: boolean;
  isSaveWhenPrinting: boolean;
  documentTemplateFilePartNames: DocumentTemplateFilePartNameModel[];
  isForSale: boolean;
  isForPurchases: boolean;
  isForCustomers: boolean;
  isForWarehouse: boolean;
  printOrderMarkers?: OrderMarker[];
  countCopy: number;
  isSortWhenPrinting: boolean;
  guid: string;
};

export type EmailTemplatesSettingsModel = {
  subject: string;
  cc: string;
  bcc: string;
  previewBeforeSending: boolean;
  attachedFileName1: string;
  attachedFile1: string;
  attachedFileName2: string;
  attachedFile2: string;
  attachedFileName3: string;
  attachedFile3: string;
  attachedFileName4: string;
  attachedFile4: string;
};

export type DocumentTemplateFilePartNameModel = {
  isChecked: boolean;
  order: number;
};

export enum DocumentTemplatePrintType {
  Sales = 1,
  Purchases,
  Customer,
}

function CreateSaveFilePartData(order: number) {
  return {
    isChecked: true,
    order: order,
  };
}

export const DEFAULT_FILE_PART_NAMES: DocumentTemplateFilePartNameModel[] = [
  CreateSaveFilePartData(1),
  CreateSaveFilePartData(2),
  CreateSaveFilePartData(3),
  CreateSaveFilePartData(4),
  CreateSaveFilePartData(5),
  CreateSaveFilePartData(6),
];

export const FILE_PART_ORDER: Pair<any>[] = [
  {
    value: '1',
    key: 1,
  },
  {
    value: '2',
    key: 2,
  },
  {
    value: '3',
    key: 3,
  },
  {
    value: '4',
    key: 4,
  },
  {
    value: '5',
    key: 5,
  },
  {
    value: '6',
    key: 6,
  },
];

export enum RestoreTemplateStatus {
  NotFound,
  ParentNotFound,
  Ok,
}
