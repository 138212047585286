import { ReactNode } from 'react';

export enum ColumnType {
  DateTime,
  Date,
  String,
  Currency,
  Weight,
  Boolean,
  Number,
  Decimal,
}

export enum ColumnSummaryType {
  Count = 'count',
  Min = 'min',
  Sum = 'sum',
}

export type ColumnInfo<T> = {
  columnType?: ColumnType;
  children?: ColumnInfo<T>[];
  fieldName?: keyof T;
  headerText?: string;
  visible?: boolean;
  defaultVisible?: boolean;
  render?: (rowData: T, rowIndex: number) => ReactNode;
  alignment?: 'left' | 'center' | 'right';
  width?: number | string;
  minWidth?: number;
  allowEditing?: boolean;
  allowFiltering?: boolean;
  allowSorting?: boolean;
  format?: any;
  summary?: {
    type: ColumnSummaryType;
  };
  calculateCellValue?: (row: T) => any;
  fixedPosition?: 'left' | 'sticky' | 'right';
};
