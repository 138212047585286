import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { dynamicDataSelectors } from '@/Redux/DynamicData';

// Shipping method tree view options for form inputs
export function useShippingMethodsFormTreeViewOptions(defaultValues?: (string | number)[]) {
  const shippingMethods = useSelector(dynamicDataSelectors.shippingMethodsData.shippingMethodsOptions);
  const [needToReset, setNeedToReset] = useState(false);

  const defaultShippingMethodIds = defaultValues;

  const shippingMethodsTreeViewItems = useMemo<TreeItemType<number>[]>(() => {
    setNeedToReset(false);

    return shippingMethods.map((item) => {
      const isDefault = Boolean(defaultShippingMethodIds?.includes(item.key));

      return {
        key: item.key,
        value: item.value,
        expanded: true,
        selected: isDefault,
      };
    });
  }, [shippingMethods, defaultValues, needToReset]);

  return {
    shippingMethodsTreeViewItems,
    setNeedToReset,
  };
}
