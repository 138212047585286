import React from 'react';
import cn from 'classnames';

// Types import
import { ClassNameProps, DataProps } from '@/Types';

// Styles import
import styles from './Checkbox.scss';

type Props = {
  onChange?: (value: boolean) => void;
  valueForSet?: boolean;
  disabled?: boolean;
  visibility?: boolean;
  dataAttributes?: DataProps;
} & ClassNameProps;

export const Checkbox: React.FC<Props> = ({
  onChange,
  valueForSet = false,
  className,
  disabled = false,
  visibility = true,
  dataAttributes,
}: Props) => {
  return (
    <span className={cn(styles.container, { [styles.hidden]: !visibility })}>
      <input
        type={'checkbox'}
        checked={valueForSet}
        className={cn(className, styles.checkbox)}
        onChange={(e) => onChange?.(e.target.checked)}
        disabled={disabled}
        {...dataAttributes}
      />
      <span className={styles.checkmark}></span>
    </span>
  );
};
