import Dpd from './Logos/dpd.svg';
import Ebay from './Logos/ebay.svg';
import ShipCheaper from './Logos/shipCheaper.svg';
import Amazon from './Logos/amazon.svg';
import Kaufland from './Logos/kaufland.svg';
import DeutschePost from './Logos/deutschePostStatus.svg';
import Dhl from './Logos/dhl.svg';
import Gls from './Logos/gls.svg';
import Ups from './Logos/ups.svg';
import Hermes from './Logos/hermes.svg';
import DhlExpress from './Logos/dhl.svg';
import Tnt from './Logos/tnt.svg';
import Ozon from './Logos/ozon.svg';

import AmazonStatus from './Logos/amazonStatus.svg';
import EbayStatus from './Logos/ebayStatus.svg';
import KauflandStatus from './Logos/kauflandStatus.svg';
import OzonStatus from './Logos/ozonStatus.svg';

import ImagePlaceholder from './Images/imagePlaceholder.png';
import ScanerScreen from './Images/scanerScreen.jpg';

export const Images = {
  ScanerScreen,
  ImagePlaceholder,
};

export const Logos = {
  Amazon,
  Ebay,
  Kaufland,
  Ozon,

  AmazonStatus,
  EbayStatus,
  KauflandStatus,
  OzonStatus,

  Dpd,
  ShipCheaper,
  DeutschePost,
  Dhl,
  Gls,
  Ups,
  DhlExpress,
  Hermes,
  Tnt,
};
