import React from 'react';
import { ClassNameProps } from '@/Types';
import styles from './OrderItemsTable.scss';
import cn from 'classnames';
import { TableButtonsSection, OrderItemsSection, OrderItemsInfoSection } from './modules';
import { useSelector } from 'react-redux';
import { OrderStatusIcons } from '@/Components/OrderStatusIcons/OrderStatusIcons';
import { useOrderStatusIcons } from './hooks/useOrderStatusIcons';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';

type Props = ClassNameProps;

export const OrderItemsTable = ({ className }: Props) => {
  const selectedPurchase = useSelector(purchasesPageSelectors.selectedPurchase);

  const { paymentIconInfo, shippingIconInfo, sellerFeedbackIconInfo, buyerFeedbackIconInfo } = useOrderStatusIcons(
    selectedPurchase,
  );

  return (
    <div className={cn(className, styles.wrapper)}>
      <div className={styles.filterAndIconsWrapper}>
        <TableButtonsSection />
        <OrderStatusIcons
          className={styles.orderStatusIcons}
          leftFeedbackIconInfo={sellerFeedbackIconInfo}
          receivedFeedbackIconInfo={buyerFeedbackIconInfo}
          paymentIconInfo={paymentIconInfo}
          shippingIconInfo={shippingIconInfo}
        />
      </div>

      <div className={styles.orderItemsContainer}>
        <OrderItemsSection className={styles.orderItemsSection} />
        <OrderItemsInfoSection className={styles.orderItemsInfoSection} />
      </div>
    </div>
  );
};
