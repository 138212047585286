import React from 'react';
import { useLocalization } from '@/Hooks';
import { ColumnInfo, ColumnType, OrderLabelPrintRow } from '@/Types';
import { Icon } from '@/Components/Icon/Icon';
import { EditIcon } from '@/Static/EditIcon';
import styles from './OrderLabelTable.scss';

export function useOrderLabelTableColumnInfo(editClick: (row: OrderLabelPrintRow) => void) {
  const {
    modalWindows: { orderLabelPrint },
  } = useLocalization();

  const columnInfos: ColumnInfo<OrderLabelPrintRow>[] = [
    {
      columnType: ColumnType.String,
      fieldName: 'marketplaceImage',
      headerText: orderLabelPrint.Columns.Market,
      alignment: 'center',
      width: '60px',
      render: (rowData: OrderLabelPrintRow) => (
        <div className={styles.logoBlock}>
          <Icon alt={''} src={rowData.marketplaceImage} className={styles.marketLogo} />
        </div>
      ),
    },
    {
      columnType: ColumnType.String,
      fieldName: 'userOrderId',
      alignment: 'center',
      headerText: orderLabelPrint.Columns.ID,
    },
    {
      columnType: ColumnType.String,
      fieldName: 'invoice',
      alignment: 'center',
      headerText: orderLabelPrint.Columns.Invoice,
    },
    {
      columnType: ColumnType.String,
      fieldName: 'countryName',
      alignment: 'center',
      headerText: orderLabelPrint.Columns.Country,
    },
    {
      columnType: ColumnType.String,
      fieldName: 'logisticDesciptions',
      alignment: 'center',
      headerText: orderLabelPrint.Columns.Packages,
    },
    {
      columnType: ColumnType.Currency,
      fieldName: 'price',
      alignment: 'center',
      headerText: orderLabelPrint.Columns.Price,
    },
    {
      columnType: ColumnType.Boolean,
      fieldName: 'isCreated',
      width: '50px',
      alignment: 'center',
      headerText: orderLabelPrint.Columns.Created,
    },
    {
      columnType: ColumnType.Boolean,
      fieldName: 'isPrinted',
      width: '50px',
      alignment: 'center',
      headerText: orderLabelPrint.Columns.Printed,
    },
    {
      columnType: ColumnType.String,
      width: '50px',
      alignment: 'center',
      headerText: orderLabelPrint.Columns.Edit,
      render: (rowData: OrderLabelPrintRow) => (
        <EditIcon onClick={() => editClick(rowData)} size={'small'} disabled={rowData.isCreated} />
      ),
    },
  ];

  return columnInfos;
}
