import { GenerateNumberRequest, MpOrdersApi } from '@/Api/MpOrders';
import { UpdateOrderNoteRequest } from '@/Api/MpOrders/Requests/UpdateOrderNoteRequest';
import { UpdateOrderNoteResponse } from '@/Api/MpOrders/Responses/UpdateOrderNoteResponse';
import { OrderModel } from '@/Models';
import { CreateOrUpdateOrderModel } from '@/Models/CreateOrUpdateOrderModel';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CopyOrderRequest } from '@/Api/MpOrders/Requests/CopyOrderRequest';
import { AppThunkApiConfig, RootState } from '@/Redux/RootReducer';
import { salesPageSelectors } from '@/Pages/Sales/services/selectors';
import { dateTimeUtils } from '@/Utils';
import { OrderType } from '@/Enums';
import { CheckGeneratedNumberForDuplicateResponse } from '@/Api/MpOrders/Responses/CheckGeneratedNumberForDuplicateResponse';
import { CheckGeneratedNumberForDuplicateRequest } from '@/Api/MpOrders/Requests/CheckGeneratedNumberForDuplicateRequest';
import { CopyOrderPreviewRequest } from '@/Api/MpOrders/Requests/CopyOrderPreviewRequest';
import { PageableResponse } from '@/Api';
import { tableUtils } from '@/Utils/TableUtils';
import { OrderSortColumn } from '@/Enums/Sales/OrderSortColumn';
import { TableFilterModel } from '@/Types/TableFilterModel';
import { OrderTableRow } from '@/Types';

const getSalesFromElastic = createAsyncThunk<
  PageableResponse<OrderModel>,
  TableFilterModel<keyof OrderTableRow> | undefined,
  AppThunkApiConfig
>('salesPage/get-sales-from-elastic', (params, api) => {
  const state: RootState = api.getState();
  const orderFilters = salesPageSelectors.orderFilters(state);
  const tableStateKey = salesPageSelectors.tableStateKey(state);
  const tableFilters = tableUtils.getTableFilterState(tableStateKey);

  return MpOrdersApi.getOrdersFromElastic({
    params: {
      dateRange: dateTimeUtils.localDateRangeToUtcString(orderFilters.dateRange),
      orderType: OrderType.Sale,
      mpAccountIds: orderFilters.mpAccountIds ?? [],
      orderStatusFilter: orderFilters.orderStatusFilter,
      searchGroup: orderFilters.searchGroup,
      searchString: orderFilters.searchString,
      orderSortColumn: (params?.sortColumn ?? tableFilters.sortColumn) as OrderSortColumn,
      isAscending: params?.isAscending ?? tableFilters.isAscending,
    },
    pageSize: params?.pageSize ?? Number(tableFilters.pageSize),
    currentPage: params?.currentPage ?? Number(tableFilters.currentPage),
  });
});

const generateNumberForOrder = createAsyncThunk<void, GenerateNumberRequest>(
  'salesPage/generate-number-for-order',
  (request: GenerateNumberRequest) => {
    return MpOrdersApi.generateNumberForOrder(request);
  },
);

const checkGeneratedNumberForOrder = createAsyncThunk<
  CheckGeneratedNumberForDuplicateResponse,
  CheckGeneratedNumberForDuplicateRequest
>('salesPage/check-generated-number', (request: CheckGeneratedNumberForDuplicateRequest) => {
  return MpOrdersApi.checkGeneratedNumberForOrder(request);
});

const getOrdersByIds = createAsyncThunk<OrderModel[], number[], AppThunkApiConfig>(
  'salesPage/get-orders-by-ids',
  (includedIds, api) => {
    const state: RootState = api.getState();
    const orderFilters = salesPageSelectors.orderFilters(state);

    return MpOrdersApi.getOrders({
      dateRange: dateTimeUtils.localDateRangeToUtcString(orderFilters.dateRange),
      orderType: OrderType.Sale,
      mpAccountIds: orderFilters.mpAccountIds ?? [],
      orderStatusFilter: orderFilters.orderStatusFilter,
      includedIds,
    });
  },
);

const createOrder = createAsyncThunk<OrderModel, CreateOrUpdateOrderModel>(
  'orderFormModal/create-order',
  async (request: CreateOrUpdateOrderModel) => {
    return MpOrdersApi.createOrder(request);
  },
);

const updateOrder = createAsyncThunk<OrderModel, CreateOrUpdateOrderModel>(
  'orderFormModal/update-order',
  async (request: CreateOrUpdateOrderModel) => {
    return MpOrdersApi.updateOrder(request);
  },
);

const createOrderPreview = createAsyncThunk<OrderModel, CreateOrUpdateOrderModel>(
  'orderFormModal/create-order-preview',
  async (request: CreateOrUpdateOrderModel) => {
    return MpOrdersApi.createOrderPreview(request);
  },
);

const updateOrderPreview = createAsyncThunk<OrderModel, CreateOrUpdateOrderModel>(
  'orderFormModal/update-order-preview',
  async (request: CreateOrUpdateOrderModel) => {
    return MpOrdersApi.updateOrderPreview(request);
  },
);

const copyOrderPreview = createAsyncThunk<OrderModel, CopyOrderPreviewRequest>(
  'orderFormModal/copy-order-preview',
  async (request) => {
    return MpOrdersApi.copyOrderPreview(request);
  },
);

const copyOrder = createAsyncThunk<OrderModel, CopyOrderRequest>(
  'orderFormModal/copy-order',
  async (request: CopyOrderRequest) => {
    return MpOrdersApi.copyOrder(request);
  },
);

const updateOrderNote = createAsyncThunk<UpdateOrderNoteResponse, UpdateOrderNoteRequest>(
  'salesPage/update-order-note',
  async (request) => {
    return MpOrdersApi.updateOrderNote(request);
  },
);

const deleteOrders = createAsyncThunk<void, number[]>('salesPage/delete-orders', async (request) => {
  return MpOrdersApi.deleteOrders(request);
});

export const salesAsyncActions = {
  getSalesFromElastic,
  generateNumberForOrder,
  checkGeneratedNumberForOrder,
  getOrdersByIds,
  createOrder,
  updateOrder,
  copyOrder,
  updateOrderNote,
  deleteOrders,
  createOrderPreview,
  updateOrderPreview,
  copyOrderPreview,
};
