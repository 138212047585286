import { useCallback, useState } from 'react';
import { DocumentTemplateRow, Nullable } from '@/Types';
import { documentStoreService, printService } from '@/Electron';
import { PrinterSettings } from '@/Electron/services/PrintService/IPrintService';
import { salesPageAsyncActions } from '@/Pages/Sales/services';
import { useDispatch, useSelector } from 'react-redux';
import { AutomaticallyGeneratedDocumentType } from '@/Enums/AutomaticallyGeneratedDocumentType';
import { NumberGenerationType } from '@/Enums';
import { DocumentTemplateApi } from '@/Api/DocumentTemplates';
import { documentPrintModalSelectors } from '@/ModalWindows/DocumentPrintModal/services/selectors';
import { useForm } from '@/Hooks/useFormWrapper';
import { DocumentPrintOrderType } from '@/Enums/DocumentPrintOrderType';
import { DocumentPrintUnionPdfModel } from '@/Models/DocumentPrintUnionPdfModel';

export type FormData = {
  orderType: DocumentPrintOrderType;
};

export const useDocumentPrintModalActions = (
  selectedDocumentTemplate: Nullable<DocumentTemplateRow>,
  selectedOrderIds: number[],
  documentNumbers: { orderId: number; numbers: string[] }[],
  numberGenerationType: Nullable<NumberGenerationType>,
  closePrintModal: () => void,
) => {
  const dispatch = useDispatch();

  const methods = useForm<FormData>({
    mode: 'onChange',
  });

  const [createDocumentModal, setCreateDocumentModal] = useState(false);
  const [selectDocumentModal, setSelectDocumentModal] = useState(false);
  const selectedDocumentNumbers = useSelector(documentPrintModalSelectors.selectedDocumentNumbers);

  const printDocument = useCallback(() => {
    if (selectedDocumentTemplate && selectedOrderIds && selectedOrderIds.length > 0) {
      const ids = selectedOrderIds.filter((value: number, index: number, self: number[]) => {
        return self.indexOf(value) === index;
      });
      const documentPrintInfo = documentStoreService.getByDocumentGuid(selectedDocumentTemplate.guid);
      const printSetting: PrinterSettings = {
        printerName: documentPrintInfo ? documentPrintInfo.printerName : '',
        isPreview: documentPrintInfo ? documentPrintInfo.isPreview : false,
        isSaveWhenPrinting: selectedDocumentTemplate.isSaveWhenPrinting,
        pathSaveWhenPrinting: selectedDocumentTemplate.pathSaveWhenPrinting,
        copyCount: selectedDocumentTemplate.countCopy,
        isSortWhenPrinting: selectedDocumentTemplate.isSortWhenPrinting,
        automaticallyGeneratedDocumentType: AutomaticallyGeneratedDocumentType.DPRNT,
      };

      printService.print(selectedDocumentTemplate.guid, ids, printSetting, () => {
        dispatch(salesPageAsyncActions.getSalesFromElastic());
      });
    }
  }, [selectedDocumentTemplate]);

  const printDirect = useCallback(
    async (printOrder?: DocumentPrintOrderType) => {
      if (selectedDocumentTemplate && documentNumbers[0] && numberGenerationType) {
        let printUnionPdfModels: DocumentPrintUnionPdfModel[] = [];
        const isMultiDocumentPrint = documentNumbers.length > 1;

        if (isMultiDocumentPrint) {
          documentNumbers.forEach((item) => {
            item.numbers.forEach((number) => {
              printUnionPdfModels.push({
                orderId: item.orderId,
                orderNumber: number,
                countCopy: selectedDocumentTemplate.countCopy,
              });
            });
          });
        } else {
          printUnionPdfModels = selectedDocumentNumbers.map((item) => ({
            orderNumber: item.documentNumber,
            countCopy: selectedDocumentTemplate.countCopy,
            orderId: documentNumbers[0].orderId,
          }));
        }

        const documentPrintOrderType = selectedDocumentTemplate.countCopy != 1 ? printOrder : undefined;

        const document = await DocumentTemplateApi.printUnionPdfFiles({
          printUnionPdfModels,
          numberGenerationType,
          documentPrintOrderType,
        });

        const documentPrintInfo = documentStoreService.getByDocumentGuid(selectedDocumentTemplate.guid);
        const printSetting: PrinterSettings = {
          printerName: documentPrintInfo ? documentPrintInfo.printerName : '',
          isPreview: documentPrintInfo ? documentPrintInfo.isPreview : false,
          isSaveWhenPrinting: selectedDocumentTemplate.isSaveWhenPrinting,
          pathSaveWhenPrinting: selectedDocumentTemplate.pathSaveWhenPrinting,
          copyCount: selectedDocumentTemplate.countCopy,
          isSortWhenPrinting: selectedDocumentTemplate.isSortWhenPrinting,
          automaticallyGeneratedDocumentType: AutomaticallyGeneratedDocumentType.DPRNT,
        };

        printService.printDirect(document, printSetting);
      }
    },
    [selectedDocumentTemplate, selectedDocumentNumbers, documentNumbers],
  );

  const print = methods.handleSubmit((formData: FormData) => {
    if (numberGenerationType) {
      if (documentNumbers.length == 1) {
        const documentNumbersCount = documentNumbers[0].numbers.length;

        if (documentNumbersCount == 0) {
          setCreateDocumentModal(true);
          return;
        } else if (documentNumbersCount > 1) {
          setSelectDocumentModal(true);
          return;
        } else {
          // Single document print
          printDirect();
        }
      } else {
        // Multi document print
        printDirect(formData.orderType);
      }
    } else {
      printDocument();
    }

    closePrintModal();
  });

  return {
    methods,
    print,
    printDirect,
    selectDocumentModal,
    setSelectDocumentModal,
    createDocumentModal,
    setCreateDocumentModal,
  };
};
