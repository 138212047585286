import React, { PropsWithChildren } from 'react';
import { ClassNameProps } from '@/Types';
import styles from './Icon.scss';
import cn from 'classnames';

type Props = {
  src: string;
  alt: string;
  onClick?: () => void;
  hidden?: boolean;
  disabled?: boolean;
  id?: string;
} & ClassNameProps;

export const Icon: React.FC<Props> = ({
  src,
  alt,
  onClick,
  hidden,
  className,
  disabled,
  id,
}: PropsWithChildren<Props>) => {
  return (
    <img
      id={id}
      className={cn(className, styles.icon, { [styles.icon_disabled]: disabled })}
      src={src}
      alt={alt}
      onClick={() => {
        if (!disabled) onClick?.();
      }}
      hidden={hidden}
    />
  );
};
