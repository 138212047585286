import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckEmailTokenResponse, GetEmailTokenResponse } from '@/Api/AppAuth';
import { globalHistory } from '@/GlobalHistory';
import { PagesRouting } from '@/Routing';
import qs from 'qs';

// Enums import
import { EmailType } from '@/Enums';

// Hocs import
import { withAuthorizationModules } from '@/Hocs';

// Hooks import
import { useLocalization } from '@/Hooks';

// Services import
import { userActionsAsync, userSelectors, userUtils } from '@/Redux/User';
import { AppDispatch } from '@/Redux/ConfigureStore';

// Styles import
import styles from './EmailConfirmation.scss';
import { Toasts } from '@/Components/Toast/Toast';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { LoaderSpinner } from '@/Components/LoaderSpinner/LoaderSpinner';

type QueryStringParams = {
  code: string;
};

function EmailConfirmationPageComponent(): React.ReactElement {
  const dispatch: AppDispatch = useDispatch();

  const location = window.location.href;
  const { code } = qs.parse(location.split('?')[1]) as QueryStringParams;
  const user = useSelector(userSelectors.currentUser);

  const {
    notifications: { EmailConfirmationComplete, EmailResendSuccess },
    authorizationPage: { Buttons, EmailConfirmation },
  } = useLocalization();

  const [loader, setLoader] = useState<{ message?: string; isLoading?: boolean }>();

  useEffect(() => {
    if (code) {
      setLoader({ message: EmailConfirmation.CheckingTheLink, isLoading: true });

      setTimeout(
        () =>
          dispatch(userActionsAsync.checkEmailToken({ code: code, emailType: EmailType.ConfirmEmail })).then(
            (response) => {
              const { user, errors, redirectToRegister } = response.payload as CheckEmailTokenResponse;

              if (errors?.length) {
                setLoader({ isLoading: false });

                if (redirectToRegister) {
                  globalHistory.push(PagesRouting.AuthorizationPages.RegistrationPage);
                }

                return;
              } // if

              Toasts.showSuccess({ title: EmailConfirmationComplete.title, text: EmailConfirmationComplete.text });

              setLoader({ message: EmailConfirmation.RedirectingToErpSystem, isLoading: true });
              setTimeout(() => globalHistory.push(userUtils.getRedirectRoute(user)), 4000);
            },
          ),
        2000,
      );
    } // if
  }, []);

  const resendEmail = () => {
    dispatch(userActionsAsync.getEmailToken({ userId: Number(user?.id), emailType: EmailType.ConfirmEmail })).then(
      (response) => {
        const { errors } = response.payload as GetEmailTokenResponse;

        if (errors?.length) {
          return;
        } // if

        Toasts.showSuccess({ title: EmailResendSuccess.title, text: EmailResendSuccess.text });
      },
    );
  };

  if (loader?.isLoading) {
    return (
      <div className={styles.authorizationInfo}>
        <LoaderSpinner visible={loader.isLoading} type="Oval" text={loader.message} />
      </div>
    );
  }

  if (user) {
    return (
      <div className={styles.authorizationInfo}>
        <div className={styles.authorizationInfoText}>
          <h4>{EmailConfirmation.PleaseConfirmYourEmailAddress}</h4>
          <p>{EmailConfirmation.WeSentAConfirmationLinkToYourEmail}</p>
          <p>{EmailConfirmation.ToContinueUsingTheShipCheaperERPSystemYouNeedToConfirmYourEmailAddress}</p>
        </div>

        <Button text={Buttons.ResendEmail} theme={BUTTON_THEMES.SUCCESS} onClick={() => resendEmail()} />
      </div>
    );
  }

  return (
    <div className={styles.authorizationInfo}>
      <div className={styles.authorizationInfoText}>
        <h4>{EmailConfirmation.EmailConfirmationLinkIsInvalid}</h4>
        <p>{EmailConfirmation.TheAccountFromThisLinkIsNotRegistered}</p>
      </div>

      <div className={styles.authorizationInfoButtons}>
        <Button
          text={Buttons.Login}
          theme={BUTTON_THEMES.SUCCESS}
          onClick={() => globalHistory.push(PagesRouting.AuthorizationPages.LoginPage)}
        />
        <Button
          text={Buttons.SignUp}
          theme={BUTTON_THEMES.SUCCESS}
          onClick={() => globalHistory.push(PagesRouting.AuthorizationPages.RegistrationPage)}
        />
      </div>
    </div>
  );
}

export const EmailConfirmationPage = withAuthorizationModules(EmailConfirmationPageComponent);
