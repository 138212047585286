import React, { useEffect } from 'react';
import { ModalProps } from '@/Types';
import { useGenerateDocumentNumbersTexts } from './hooks/useGenerateDocumentNumbersTexts';
import { useLocalization } from '@/Hooks';
import sharedStyles from '@/ModalWindows/ModalWindows.scss';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Modal } from '@/Components/Modal/Modal';
import { Label } from '@/Components/Controls/Label/Label';
import { FormCheckbox } from '@/Components/Controls/Checkbox/FormCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { emailSendModalAsyncActions } from '@/ModalWindows/EmailSendModal/services/reducer';
import { documentPrintModalAsyncActions } from '@/ModalWindows/DocumentPrintModal/services/reducer';
import { WINDOW_MODAL_TYPE } from '@/ModalWindows/services';
import { generateDocumentNumberModalSelectors } from './services/selectors';
import { useGenerateDocumentSelectTemplate } from './hooks/useGenerateDocumentSelectTemplate';
import { documentPrintModalSelectors } from '@/ModalWindows/DocumentPrintModal/services/selectors';
import { emailSendModalSelectors } from '@/ModalWindows/EmailSendModal/services/selectors';
import { FormInput } from '@/Components/Controls/Input/FormInput';
import { stringUtils } from '@/Utils';
import { FormData, useGenerateDocumentNumbersForm } from './hooks/useGenerateDocumentNumbersForm';
import { useTourActions } from '@/Components/Tours/hooks/useTourActions';

type Props = ModalProps;

export function GenerateDocumentNumberModal({ closeModalWindow }: Props) {
  const dispatch = useDispatch();
  const documentPrintType = WINDOW_MODAL_TYPE[location.pathname];
  const numberGenerationType = useSelector(generateDocumentNumberModalSelectors.numberGenerateType);

  const {
    buttons: { Yes, No },
    modalWindows: { generateDocumentNumbers },
  } = useLocalization();

  const {
    onSubmit,
    onIsSortChange,
    onCountCopyChange,
    onPrintChange,
    onSendEmailChange,
    methods: { register, setValue, watch },
  } = useGenerateDocumentNumbersForm(numberGenerationType);

  const emailTemplates = useSelector(emailSendModalSelectors.rows);
  const documentTemplates = useSelector(documentPrintModalSelectors.rows);

  const { runTour } = useTourActions();
  const { confirmationText, modalWindowTitle } = useGenerateDocumentNumbersTexts(numberGenerationType);
  const { selectedPrintTemplate, selectedEmailTemplate } = useGenerateDocumentSelectTemplate(numberGenerationType);

  useEffect(() => {
    if (!emailTemplates.length || !documentTemplates.length) {
      dispatch(emailSendModalAsyncActions.getEmailTemplates());
      dispatch(documentPrintModalAsyncActions.getTemplatesRequest(documentPrintType));
    }
  }, []);

  const countCopy = watch('countCopy');

  useEffect(() => {
    if (!countCopy) {
      setValue('countCopy', selectedPrintTemplate?.generateNumberCountCopy);
      setValue('isSortWhenPrinting', selectedPrintTemplate?.isSortWhenPrinting);
    }

    setValue('printDocument', selectedPrintTemplate?.generateNumberPrintDocument);
    setValue('sendOrderEmail', selectedEmailTemplate?.generateNumberSendOrderEmail);
  }, [selectedPrintTemplate, selectedEmailTemplate]);

  return (
    <Modal
      onClose={closeModalWindow}
      className={sharedStyles.modalWrapper}
      onDocsClick={() => runTour('documentCreatingTour')}
    >
      <div className={sharedStyles.modalDescription}>
        <h4 id={'generateNumberTitle'}>{modalWindowTitle}</h4>
      </div>

      <p dangerouslySetInnerHTML={{ __html: confirmationText }} />
      <form onSubmit={onSubmit}>
        <div id={'documentFunctions'}>
          <div className={sharedStyles.modalActions}>
            <div className={sharedStyles.printWrapper}>
              <Label
                text={stringUtils.insertParamsToString(generateDocumentNumbers.PrintDocument, [
                  selectedPrintTemplate?.name || '',
                ])}
                reverse
              >
                <FormCheckbox<FormData>
                  name={'printDocument'}
                  register={register}
                  disabled={!selectedPrintTemplate}
                  onChange={onPrintChange(selectedPrintTemplate!)}
                />
              </Label>

              {selectedPrintTemplate && (
                <>
                  <FormInput<FormData>
                    register={register}
                    name={'countCopy'}
                    type={'number'}
                    min={1}
                    max={10}
                    onChange={onCountCopyChange(selectedPrintTemplate)}
                  />

                  {countCopy != 1 && (
                    <Label text={generateDocumentNumbers.Sorting} reverse>
                      <FormCheckbox<FormData>
                        name={'isSortWhenPrinting'}
                        register={register}
                        onChange={onIsSortChange(selectedPrintTemplate)}
                      />
                    </Label>
                  )}
                </>
              )}
            </div>
            {!selectedPrintTemplate && (
              <div className={sharedStyles.validationMessage}>{generateDocumentNumbers.TemplateNotExists}</div>
            )}
          </div>

          <div className={sharedStyles.modalActions}>
            <Label
              text={stringUtils.insertParamsToString(generateDocumentNumbers.SendEmail, [
                selectedEmailTemplate?.name || '',
              ])}
              reverse
            >
              <FormCheckbox<FormData>
                name={'sendOrderEmail'}
                register={register}
                disabled={!selectedEmailTemplate}
                onChange={onSendEmailChange(selectedEmailTemplate!)}
              />
            </Label>
            {!selectedEmailTemplate && (
              <div className={sharedStyles.validationMessage}>{generateDocumentNumbers.TemplateNotExists}</div>
            )}
          </div>
        </div>

        <div className={sharedStyles.buttonsWrapper}>
          <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={No} onClick={closeModalWindow} />
          <Button type={'submit'} autoFocus={true} theme={BUTTON_THEMES.SUCCESS} text={Yes} />
        </div>
      </form>
    </Modal>
  );
}
