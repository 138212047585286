import React from 'react';
import { Icon } from '@/Components/Icon/Icon';
import { Icons } from '@/Static/IconsRes';
import Button from 'devextreme-react/button';
import styles from './TableExportButton.scss';

type Props = {
  onExport: (value: boolean) => void;
  onlySelected?: boolean;
};

export const TableExportButton = ({ onExport, onlySelected = false }: Props): React.ReactElement => {
  return (
    <Button onClick={() => onExport(onlySelected)}>
      <Icon
        alt="clearFiltersIcon"
        src={onlySelected ? Icons.OutcomingSelected : Icons.Outcoming}
        className={styles.icon}
      />
    </Button>
  );
};
