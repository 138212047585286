import { OrderTableRow } from '@/Types';

export const MERGED_COLUMNS: Partial<{ [K in keyof OrderTableRow]: boolean }> = {
  id: true,
  userOrderId: true,
  mpOrderNumber: true,
  extendedOrderId: true,
  orderType: true,
  orderStatus: true,

  buyerMarketplaceAccount: true,
  sellerMarketplaceAccount: true,
  accountName: true,
  buyerEmail: true,
  customerNumber: true,

  refundTime: true,
  refundType: true,
  refundStatus: true,
  refundAmount: true,

  priceBruttoWithShipping: true,
  amountPaid: true,
  shippingPrice: true,
  actualShippingCostNetto: true,
  openAmount: true,

  currencyId: true,
  paymentStatus: true,
  cancelStatus: true,
  paymentMethod: true,

  orderFixFee: true,
  profit: true,

  appCreatedTime: true,
  appLastModifiedTime: true,
  synchTime: true,
  mpLastModifiedTime: true,
  mpCreatedTime: true,
  paidTime: true,
  actualDeliveryTime: true,

  shippingMethodDescription: true,
  actualShippingMethodDescription: true,
  estimatedDeliveryTimeMax: true,
  handleByTime: true,
  shippedTime: true,

  shippingCompanyName: true,
  shippingFirstName: true,
  shippingLastName: true,
  shippingAdditionalLine: true,
  shippingStreet: true,
  shippingHouseNo: true,
  shippingPostalCode: true,
  shippingCityName: true,
  shippingCountryName: true,
  shippingCountryCode: true,
  shippingPhoneNumber: true,

  invoiceCompanyName: true,
  invoiceFirstName: true,
  invoiceLastName: true,
  invoiceAdditionalLine: true,
  invoiceStreet: true,
  invoiceHouseNo: true,
  invoicePostalCode: true,
  invoiceCityName: true,
  invoiceCountryName: true,
  invoiceCountryCode: true,
  invoicePhoneNumber: true,

  internalNote: true,
  externalNote: true,
  termsOfPayment: true,

  deliveryNoteNumber: true,

  orderNumber: true,

  currentInvoiceNumber: true,
  currentInvoiceCreatedTime: true,

  currentCancelInvoiceNumber: true,
  currentCancelInvoiceCreatedTime: true,

  buyerNote: true,

  archived: true,
  exported: true,
  canceledNotPaid: true,
  canceledRefunded: true,
  actualShippingMethodCode1: true,
};
